import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { checkSimulatorFormulaVariable } from "./api/CheckSimulatorFormulaVariable";
import { createSimulator } from "./api/createSimulator";
import { getSimulatorById } from "./api/GetSimulatorById";
import { getSimulatorInputVariables } from "./api/GetSimulatorInputVariables";
import { getSimulatorKpis } from "./api/GetSimulatorKpis";
import { getSimulators } from "./api/GetSimulators";
import { getSimulatorFunctionTemplates } from "./api/GetSimulatorVariableTemplates";
import { getSimulatorVariableTypeTemplates } from "./api/GetSimulatorVariableTypeTemplates";
import { updateSimulator } from "./api/updateSimulator";
import { updateSimulatorValidation } from "./api/updateSimulatorValidation";
import { Simulator, SimulatorCreateRequest } from "./types/Simulator";
import { SimulatorFunctionBlock } from "./types/SimulatorFunctionBlock";
import { parseRawSimulator, parseSimulator } from "./utils/transformSimulatorTypes";

export const onLoadSimulators = createAsyncThunk("simulatorBuilder/onLoadSimulators", async (_, thunk) => {
  try {
    const req = await getSimulators();
    const parsedSimulators = req.data.map((simulator) => parseSimulator(simulator));
    return parsedSimulators;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

export const onLoadSimulatorFunctionTemplates = createAsyncThunk(
  "simulatorBuilder/onLoadSimulatorFunctionTemplates",
  async () => {
    const req = await getSimulatorFunctionTemplates();
    return req.data;
  }
);

export const onLoadSimulatorVariableTypeTemplates = createAsyncThunk(
  "simulatorBuilder/onLoadSimulatorVariableTypeTemplates",
  async () => {
    const req = getSimulatorVariableTypeTemplates();
    return (await req).data;
  }
);

export const onCreateSimulator = createAsyncThunk(
  "simulatorBuilder/onCreateSimulator",
  async (simulator: SimulatorCreateRequest) => {
    const req = await createSimulator(simulator);

    return req.data;
  }
);

export const onLoadSimulatorKpis = createAsyncThunk(
  "simulatorBuilder/onLoadSimulatorKpis",
  async (simulatorId: string) => {
    const req = await getSimulatorKpis(simulatorId);
    return { kpis: req.data, simulatorId };
  }
);

export const onLoadSimulatorInputVariables = createAsyncThunk(
  "simulatorBuilder/onLoadSimulatorInputVariables",
  async (simulatorId: string) => {
    const req = await getSimulatorInputVariables(simulatorId);
    return { simulatorId, variables: req.data };
  }
);

export const onUpdateSimulator = createAsyncThunk(
  "simulatorBuilder/onUpdateSimulator",
  async (
    requestData: {
      blocks: Array<SimulatorFunctionBlock>;
      openModal?: boolean;
      simulator: Simulator;
      simulatorId: string;
    },
    { rejectWithValue }
  ) => {
    const { blocks, openModal = true, simulator, simulatorId } = requestData;
    const parsedRawSimulator = parseRawSimulator(simulator, blocks);

    // Endpoint sem validação
    const req = await updateSimulator(simulatorId, parsedRawSimulator);

    try {
      // Endpoint com validação
      const parsedSimulator = parseSimulator(req.data);
      const newParsedRawSimulator = parseRawSimulator(parsedSimulator, blocks);
      await updateSimulatorValidation(simulatorId, newParsedRawSimulator);
    } catch (error) {
      const errorData = error as AxiosError;
      const customError = {
        response: {
          data: {
            error: errorData.message,
            message: {
              invalid_vars: errorData.response?.data?.invalid_vars,
              message: errorData.response?.data?.message || "An error occurred during validation",
            },
            status: errorData.response?.status || 400,
          },
          openModal: openModal,
          req_data: req.data,
        },
      };
      return rejectWithValue(customError);
    }

    return { data: req.data, openModal };
  }
);

export const onLoadSimulatorById = createAsyncThunk(
  "simulatorBuilder/onLoadSimulatorById",
  async (simulatorId: string) => {
    const req = await getSimulatorById(simulatorId);
    const parsedSimulator = parseSimulator(req.data);
    return parsedSimulator;
  }
);

export const onCheckSimulatorFormulaVariable = createAsyncThunk(
  "simulatorBuilder/onCheckSimulatorFormulaVariable",
  async (formula: string) => {
    const req = await checkSimulatorFormulaVariable(formula);

    return { data: req.data, formula: formula };
  }
);
