import { ObjectOf } from "@common/types/ObjectOf";
import { nodeUpdated } from "@productBuilder/productBuilderSlice";
import { PeerChoice } from "@productBuilder/types/NodeOptions";
import { Dispatch } from "react";
import { Node } from "react-flow-renderer";

type ActiveSignTypes = {
  ecpf: boolean;
  idrc: boolean;
};

type ActiveProponents = ObjectOf<PeerChoice>;

export const updateSignTypesNode = (
  nodes: Array<Node>,
  activeNode: Node,
  updatedSignTypes: ActiveSignTypes,
  dispatch: Dispatch<unknown>
) => {
  const latestNode = nodes.find((node) => node.id === activeNode.id);
  if (!latestNode) return;

  const updatedNode = {
    ...latestNode,
    data: {
      ...latestNode.data,
      options: {
        ...latestNode.data.options,
        inputs: latestNode.data.options.inputs ? [...latestNode.data.options.inputs] : [],
      },
    },
  };

  if (updatedNode.data.options.inputs.length > 0) {
    updatedNode.data.options.inputs[0] = {
      ...updatedNode.data.options.inputs[0],
      active_sign_types: updatedSignTypes,
    };
  }

  dispatch(nodeUpdated(updatedNode));
};

export const updatePeersToSignNode = (
  nodes: Array<Node>,
  activeNode: Node,
  activeSignTypes: ActiveSignTypes,
  activeProponents: ActiveProponents,
  proponent: { id: string; name: string },
  active: boolean,
  setActiveProponents: (proponents: ActiveProponents) => void,
  dispatch: Dispatch<unknown>
) => {
  const latestNode = nodes.find((node) => node.id === activeNode.id);

  if (!latestNode) return;

  const updatedNode = {
    ...latestNode,
    data: {
      ...latestNode.data,
      options: {
        ...latestNode.data.options,
        inputs: latestNode.data.options.inputs ? [...latestNode.data.options.inputs] : [],
      },
    },
  };

  if (updatedNode.data.options.inputs.length > 0) {
    updatedNode.data.options.inputs[0] = {
      ...updatedNode.data.options.inputs[0],
      active_sign_types: {
        ecpf: activeSignTypes.ecpf,
        idrc: activeSignTypes.idrc,
      },
      choices: {
        ...updatedNode.data.options.inputs[0].choices,
        [proponent.id]: { id: proponent.id, peer: proponent.name, selected: active },
      },
    };
  }

  setActiveProponents({
    ...activeProponents,
    [proponent.id]: { id: proponent.id, peer: proponent.name, selected: active },
  });

  dispatch(nodeUpdated(updatedNode));
};
