import { ColumnData } from "@board/boardSlice";

export enum RegistryAnalysisColumn {
  COMPLETED = "completed",
  IN_PROGRESS = "in_progress",
  PREPARATION = "preparation",
}

export type RegistryAllColumnsType = {
  completed: ColumnData;
  in_progress: ColumnData;
  preparation: ColumnData;
};

export enum RegistryAnalysisStatus {
  COMPLETED = "finished",
  IN_PROGRESS = "in_progress",
  PREPARATION = "preparation",
}
export enum RegistryAnalysisTags {
  AWAITING_ONR = "awaiting_ONR",
  DOCUMENTATION = "documentation",
  EMOLUMENTS_PAYMENT = "emoluments_payment",
  ITBI_ISSUING = "ITBI_issuing",
  ITBI_PAYMENT = "ITBI_payment",
  PAYMENT_EXIGENCY = "payment_exigency",
  PRE_BOOKING_PAYMENT = "pre_booking_payment",
  SIGNATURE = "signature",
}
export enum RegistrTagsTranslation {
  awaiting_ONR = "Aguardando ONR",
  documentation = "Documentação",
  emoluments_payment = "Pagamento de Emolumentos",
  ITBI_issuing = "Emitindo ITBI",
  ITBI_payment = "Pagamento ITBI",
  payment_exigency = "Pagamento de Exigência",
  pre_booking_payment = "Pagamento de Prenotação",
  signature = "Assinatura",
}
export enum RegistryFinishOptions {
  REGISTRY_CANCELLED = "registry_cancelled",
  REGISTRY_COMPLETED = "registry_completed",
  REGISTRY_IN_ISSUE = "registry_in_issue",
}
export enum RegistryFinishOptionsTranslation {
  registry_cancelled = "Registro Cancelado",
  registry_completed = "Registro Finalizado",
  registry_in_issue = "Registro em Emissão",
}
