import { CustomReduxSimulatorBuilderError } from "@common/types/CustomError";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ObjectOf } from "../common/types/ObjectOf";
import Kpi from "../productBuilder/types/Kpi";
import { NodeOptionsDataInput } from "../productBuilder/types/NodeOptions";
import { SelectedFunctionType } from "./components/SimulatorTester/SimulatorTester";
import {
  onCheckSimulatorFormulaVariable,
  onCreateSimulator,
  onLoadSimulatorById,
  onLoadSimulatorFunctionTemplates,
  onLoadSimulatorInputVariables,
  onLoadSimulatorKpis,
  onLoadSimulators,
  onLoadSimulatorVariableTypeTemplates,
  onUpdateSimulator,
} from "./thunkFunctions";
import { SimulatorTesterSearchData } from "./types/simulationTester";
import { Simulator, SimulatorRaw, SimulatorTesterResultDBResponse } from "./types/Simulator";
import { SimulatorResultCard, SimulatorResultHighlight } from "./types/SimulatorDashboard";
import { SimulatorFormView } from "./types/SimulatorFormView";
import { SimulatorFunctionTemplate, SimulatorSelectedVariable } from "./types/SimulatorFunctionTemplate";
import { SimulatorVariableTypeTemplate } from "./types/SimulatorVariableTypeTemplate";
import { parseSimulator } from "./utils/transformSimulatorTypes";

interface SimulatorBuilderState {
  createSimulator: boolean; // Abre o modal de criação de simulador
  currentView: SimulatorFormView; // Modal exibido na tela: Edicao simulador, ediçao tenant ou direto o stepResume
  duplicating: boolean; // Verificar se é uma duplicação ou criação de modal;
  functionTemplates: Array<SimulatorFunctionTemplate>; // Lista de templates de função
  hasAlterations: boolean; // Verifica se houve alterações no simulador
  initialSimulatorState: Simulator | null; // Estado inicial do simulador em uso (usado para resetar o state)
  isEditSimulatorTenant: boolean; // valida se o usuário está no fluxo de apenas edicao do tenant de um simulador
  isLoading: boolean; // Verifica se está carregando
  isResultActive: boolean; // Verifica se o toggle "utiliza resultados" está ativo
  selectedFunction: SelectedFunctionType | null; // Função selecionada (simulator tester)
  selectedVariable: SimulatorSelectedVariable; // Variável selecionada (simulator builder)
  simulatorData: Simulator | null; // Simulador em uso (criação/edição)
  simulatorInputVariables: ObjectOf<Array<NodeOptionsDataInput>>; // Variáveis do simulador (product builder)
  simulatorKpis: ObjectOf<Array<Kpi> | undefined>; // Lista de KPIs de simuladores (usado no product builder)
  simulators: ObjectOf<Simulator>; // Lista de simuladores criados
  simulatorTesterSearchData: SimulatorTesterSearchData; // Dados de busca do Tester do Simulador
  testerResult: SimulatorTesterResultDBResponse | null; // Resultado do teste do simulador
  variableTypes: Array<SimulatorVariableTypeTemplate>; // Lista de types possíveis para setar uma variável no simulator
}

const initialState: SimulatorBuilderState = {
  createSimulator: false,
  currentView: SimulatorFormView.NAME,
  duplicating: false,
  functionTemplates: [],
  hasAlterations: false,
  initialSimulatorState: null,
  isEditSimulatorTenant: false,
  isLoading: true,
  isResultActive: false,
  selectedFunction: null,
  selectedVariable: { functionKey: "", id: "", label: "", name: "", type: null, value: "" },
  simulatorData: null,
  simulatorInputVariables: {},
  simulatorKpis: {},
  simulatorTesterSearchData: { blocks: [], functions: [], searchedText: "", type: "", variables: [] },
  simulators: {},
  testerResult: null,
  variableTypes: [],
};

const EMPTY_SIMULATOR = {
  blocks: [],
  created_at: 0,
  dashboard: { cards: [], highlight: [], tables: [] },
  is_active: false,
  name: "",
  simulator_id: "",
  tenant_code: "",
  tenant_name: "",
  variables: [],
};

export type SimulatorUpdate<T extends keyof Simulator> = {
  key?: T;
  value: Simulator | Simulator[T];
};

const simulatorBuilderSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadSimulators.fulfilled, (state, action) => {
        action.payload.forEach((simulator) => (state.simulators[simulator.simulator_id] = simulator));
      })
      .addCase(onLoadSimulatorFunctionTemplates.fulfilled, (state, action) => {
        state.functionTemplates = action.payload;
      })
      .addCase(onLoadSimulatorVariableTypeTemplates.fulfilled, (state, action) => {
        state.variableTypes = action.payload;
      })
      .addCase(onCreateSimulator.fulfilled, (state, action) => {
        const simulator = action.payload;

        state.simulators[simulator.simulator_id] = simulator;
        state.initialSimulatorState = simulator;
        state.simulatorData = simulator;
      })
      .addCase(onLoadSimulatorById.fulfilled, (state, action) => {
        const simulator = action.payload;

        state.simulators[simulator.simulator_id] = simulator;
        state.simulatorData = simulator;
      })
      .addCase(onLoadSimulatorKpis.fulfilled, (state, action) => {
        const { kpis, simulatorId } = action.payload;
        state.simulatorKpis[simulatorId] = kpis;
      })
      .addCase(onLoadSimulatorInputVariables.fulfilled, (state, action) => {
        const { simulatorId, variables } = action.payload;
        state.simulatorInputVariables[simulatorId] = variables;
      })
      .addCase(onUpdateSimulator.fulfilled, (state, action) => {
        const { data, openModal } = action.payload;
        const simulator = parseSimulator(data as SimulatorRaw);

        state.simulators[simulator.simulator_id] = simulator;

        if (openModal) {
          state.initialSimulatorState = simulator;
          state.simulatorData = simulator;
        }
      })
      .addCase(onCheckSimulatorFormulaVariable.fulfilled, (__, action) => {
        const { data } = action.payload;

        if (data.status !== 200) return;
      })
      .addCase(onUpdateSimulator.rejected, (state, action) => {
        const openModal = (action.payload as CustomReduxSimulatorBuilderError).response.openModal!;
        const simulator = parseSimulator((action.payload as CustomReduxSimulatorBuilderError).response.req_data!);

        state.simulators[simulator.simulator_id] = simulator;

        if (openModal) {
          state.initialSimulatorState = simulator;
          state.simulatorData = simulator;
        }
      }),
  initialState,
  name: "simulatorBuilder",
  reducers: {
    duplicateSimulator(state, action: PayloadAction<boolean>) {
      state.duplicating = action.payload;
    },
    onChangingCurrentView(state, action: PayloadAction<SimulatorFormView>) {
      state.currentView = action.payload;
    },
    onChangingIsEditSimulatorTenant(state, action: PayloadAction<boolean>) {
      state.isEditSimulatorTenant = action.payload;
    },
    setDashboardCards(state, action: PayloadAction<Array<SimulatorResultCard>>) {
      if (!state.simulatorData) return;

      state.simulatorData.dashboard.cards = action.payload;
    },
    setDashboardHighlights(state, action: PayloadAction<Array<SimulatorResultHighlight>>) {
      if (!state.simulatorData) return;

      state.simulatorData.dashboard.highlight = action.payload;
    },
    simulatorBuilderChanged<T extends keyof Simulator>(
      state: SimulatorBuilderState,
      action: PayloadAction<SimulatorUpdate<T>>
    ) {
      const { key, value } = action.payload;

      if (!key) {
        state.simulatorData = value as Simulator;
        return;
      }

      state.simulatorData![key] = value as Simulator[T];
    },
    simulatorBuilderHasAlterations(state, action: PayloadAction<boolean>) {
      state.hasAlterations = action.payload;
    },
    simulatorBuilderModalClosed(state) {
      state.initialSimulatorState = null;
      state.selectedVariable.id = null;
      state.selectedVariable.label = null;
      state.simulatorData = null;
    },
    simulatorBuilderModalOpened(state, action: PayloadAction<string>) {
      const simulators = state.simulators;

      state.initialSimulatorState = simulators[action.payload] ?? EMPTY_SIMULATOR;
      state.simulatorData = simulators[action.payload] ?? EMPTY_SIMULATOR;
    },
    simulatorBuilderVariableSelected(state, action: PayloadAction<{ functionKey: string; id: string }>) {
      const { functionKey, id } = action.payload;

      state.selectedVariable.functionKey = functionKey;
      state.selectedVariable.id = id;
      state.selectedVariable.label =
        state.simulatorData?.variables.find((variable) => variable.id === id)?.label ?? null;
      state.selectedVariable.name = state.simulatorData?.variables.find((variable) => variable.id === id)?.name ?? null;
      state.selectedVariable.value =
        state.simulatorData?.variables.find((variable) => variable.id === id)?.value ?? null;
      state.selectedVariable.type = state.simulatorData?.variables.find((variable) => variable.id === id)?.type ?? null;
    },
    simulatorResult(state, action: PayloadAction<SimulatorTesterResultDBResponse | null>) {
      state.testerResult = action.payload;
    },
    simulatorResultLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    simulatorTesterFunctionClick(state, action: PayloadAction<SelectedFunctionType | null>) {
      state.selectedFunction = action.payload;
    },
    simulatorTesterResultActive(state, action: PayloadAction<boolean>) {
      state.isResultActive = action.payload;
    },
    simulatorTesterSearchChanged(state, action: PayloadAction<SimulatorTesterSearchData>) {
      state.simulatorTesterSearchData = action.payload;
    },
  },
});

export const {
  duplicateSimulator,
  onChangingCurrentView,
  onChangingIsEditSimulatorTenant,
  setDashboardCards,
  setDashboardHighlights,
  simulatorBuilderChanged,
  simulatorBuilderHasAlterations,
  simulatorBuilderModalClosed,
  simulatorBuilderModalOpened,
  simulatorBuilderVariableSelected,
  simulatorResult,
  simulatorResultLoading,
  simulatorTesterFunctionClick,
  simulatorTesterResultActive,
  simulatorTesterSearchChanged,
} = simulatorBuilderSlice.actions;

export default simulatorBuilderSlice.reducer;
