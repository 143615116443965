import { dialogCalled } from "@common/commonSlice";
import {
  activeNodeChanged,
  nodeDuplicated,
  nodeRemoved,
  nodeUpdated,
  proponentsChanged,
} from "@productBuilder/productBuilderSlice";
import { ActionNodeData } from "@productBuilder/types/NodeData";
import Proponent from "@productBuilder/types/Proponent";
import { Node } from "react-flow-renderer";
import { useAppDispatch } from "../../../store";

export const handleDuplicateActiveNode = (
  activeNode: Node<unknown> | null,
  hasChanges: boolean,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  if (activeNode) {
    if (hasChanges) {
      dispatch(
        dialogCalled({
          actions: [
            { title: "Cancelar" },
            { onClick: () => handleDuplicateActiveNodeConfirm(activeNode, dispatch), title: "Confirmar" },
          ],
          body: `Salve as alterações para manter as mudanças desse nó, ou clique em "Confirmar" para
          duplicá-lo sem as mudanças.`,
          title: "Você está duplicando um nó",
          type: "default",
        })
      );
    } else {
      handleDuplicateActiveNodeConfirm(activeNode, dispatch);
    }
  }
};

export const handleDuplicateActiveNodeConfirm = (
  activeNode: Node<unknown>,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  dispatch(nodeDuplicated(activeNode));
  dispatch(activeNodeChanged(null));
  dispatch(dialogCalled(null));
};

export const handleDeleteActiveNode = (
  activeNode: Node<unknown> | null,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  dispatch(
    dialogCalled({
      actions: [
        { title: "Cancelar" },
        { onClick: () => handleDeleteActiveNodeConfirm(activeNode, dispatch), title: "Confirmar" },
      ],
      body: "Ao confirmar a exclusão, o nó perderá todas as conexões que possuir",
      title: "Você está deletando um nó",
      type: "default",
    })
  );
};

export const handleDeleteActiveNodeConfirm = (
  activeNode: Node<unknown> | null,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  if (activeNode) {
    dispatch(nodeRemoved((activeNode as Node).id));
    dispatch(activeNodeChanged(null));
    dispatch(dialogCalled(null));
  }
};

export const handleSaveStartFlowNode = (
  temporaryProponents: Array<Proponent>,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  dispatch(proponentsChanged(temporaryProponents));
};

export const handleSaveActionNode = (
  activeNode: Node<unknown> | null,
  warningMessage: string,
  selectedProduct: string | null,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  const actionNode = activeNode as Node<ActionNodeData>;
  const updatedActionNode: Node<ActionNodeData> = JSON.parse(JSON.stringify(actionNode));
  if (actionNode.data.action === "warning") {
    updatedActionNode.data.custom_data.warningMessage = warningMessage;
  }
  if (actionNode.data.action === "jump") {
    updatedActionNode.data.custom_data.jumpToProduct = selectedProduct;
  }
  dispatch(nodeUpdated(updatedActionNode));
};
