import { PeerDataActionRaw } from "@analysis/types/AnalysisDetails/PeerData/PeerDataActionRaw";
import { Action } from "@analysis/types/AnalysisDetails/Webapp/Action";
import { Provider } from "@analysis/types/AnalysisDetails/Webapp/Provider";
import { post } from "../../common/utils/apiUtils";

export type ActionManagerBody = {
  analysis_id: string;
  cns?: string;
  peers: Array<PeerDataActionRaw>;
  pendency_id: string;
  product_id: string;
  provider: Provider;
};

/** Endpoint que gera as actions de uma conexão.
 * @param analysisId ID da conexão.
 * @returns Mensagem de sucesso
 * @remarks Endpoint DECISOR.
 */
export const analysisActionManager = (body: ActionManagerBody) => post<Array<Action>>(`/webapp/action`, body);
