import { LqdCheckIcon, LqdCloseIcon, LqdInput, LqdSelect, LqdTypography } from "@/liquid-components/src";
import { SelectOption } from "@/liquid-components/src/components/Select/types/SelectOption";
import { ObjectOf } from "@common/types/ObjectOf";
import { Box, Stack } from "@mui/material";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { onCheckSimulatorFormulaVariable } from "@simulatorBuilder/thunkFunctions";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../store";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";
import { getFormulaType } from "./utils/getFormulaType";

const FORMULA_KEY = "formula_str";

export default function SimulatorFunctionBlocksFunctionFormulaLayout(props: LayoutProps) {
  const {
    currentBlock,
    editVariableButton,
    func,
    newVariableButton,
    onBlurFunction,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    resultInputs,
    variableInputs,
  } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);

  const dispatch = useAppDispatch();

  const blocks = simulatorData?.blocks;

  const [formulaValue, setFormulaValue] = useState("");
  const [validatingFormula, setValidatingFormula] = useState(false);
  const [wrongFormula, setWrongFormula] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const getFormulaValue = useMemo(() => {
    return func.variables[FORMULA_KEY] as string;
  }, [func]);

  const checkNeededVariables = useMemo(() => {
    if (!Object.keys(func.variables)?.length) return [];

    const allVars = Object.keys(func.variables).filter((keys) => keys !== FORMULA_KEY);

    if (!allVars.length) return [];

    return allVars;
  }, [func]);

  const filteredVariableOptions = renderOptions.variables.filter(
    (variable) => variable.category !== "default" && variable.type !== "boolean"
  );

  const formatFilterOptions: Array<SelectOption> = filteredVariableOptions.map((option, index) => ({
    id: String(index),
    label: option.label,
    value: option.value,
  }));

  const getFormulaTypeCallback = useCallback(
    (index: number) => {
      if (validatingFormula || !getFormulaValue) return "";

      const type = getFormulaType(getFormulaValue, index);

      return type;
    },
    [getFormulaValue]
  );

  const updateFunction = async (formula: string) => {
    if (!isMounted) return;

    try {
      setValidatingFormula(true);

      const replaceCommaForDot = formula.replace(/,/g, ".");

      const { data } = await dispatch(onCheckSimulatorFormulaVariable(replaceCommaForDot as string)).unwrap();

      const neededVariables = data.variables;

      const newKeys: ObjectOf<string> = {};

      const hasNotVariablesValues = Object.keys(func.variables).filter((keys) => keys !== FORMULA_KEY).length === 0;

      if (neededVariables.length > 0) {
        neededVariables.forEach((key) => {
          if (hasNotVariablesValues) {
            newKeys[key] = "";
          } else {
            newKeys[key] = func.variables[key] as string;
          }
        });
      }

      const updatedFunction = {
        ...func,
        variables: {
          ...newKeys,
          formula_str: formula,
        },
      };

      const updatedFunctions = currentBlock.functions.map((funct) =>
        funct.index === func.index ? updatedFunction : funct
      );

      const updatedBlock = { ...currentBlock, functions: updatedFunctions };
      const updatedBlocks = blocks?.length
        ? blocks.map((block) => (block.block_id === currentBlock.block_id ? updatedBlock : block))
        : [currentBlock];

      setWrongFormula(false);

      dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
    } catch (error) {
      setWrongFormula(true);

      const updatedFunction = {
        ...func,
        variables: {
          formula_str: "",
        },
      };

      const updatedFunctions = currentBlock.functions.map((funct) =>
        funct.index === func.index ? updatedFunction : funct
      );

      const updatedBlock = { ...currentBlock, functions: updatedFunctions };
      const updatedBlocks = blocks?.length
        ? blocks.map((block) => (block.block_id === currentBlock.block_id ? updatedBlock : block))
        : [currentBlock];

      dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
    } finally {
      setValidatingFormula(false);
    }
  };

  useEffect(() => {
    if (!func?.variables?.[FORMULA_KEY]) return;

    setFormulaValue(func.variables[FORMULA_KEY] as string);
  }, [func]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mt: 2.5 }}>
        Fórmula:
      </LqdTypography>
      <Stack spacing={1} sx={{ ml: 5, mt: 1 }}>
        {Object.entries(variableInputs).map(([key], index) => {
          return (
            <LqdInput
              boxSize="small"
              characterLimit={120}
              disabled={validatingFormula}
              fullBox={true}
              key={`${key}-${index}`}
              label={""}
              onBlur={updateFunction}
              setValue={(value) => {
                setFormulaValue(value);
              }}
              showCharacterLimit={false}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: wrongFormula ? "1px solid rgba(253, 0, 0, 1)" : "1px solid rgba(227, 235, 235, 1)",
                height: "30px",
              }}
              value={formulaValue}
            />
          );
        })}
      </Stack>
      {validatingFormula ? (
        <Stack alignItems="center" direction="row" gap={1} height="30px" justifyContent="center">
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
            Validando...
          </LqdTypography>
        </Stack>
      ) : null}
      {checkNeededVariables.length > 0 && !!getFormulaValue && !validatingFormula ? (
        <Stack alignItems="center" direction="row" gap={1} justifyContent="center">
          <LqdTypography
            border="1px solid rgba(162, 163, 167, 1)"
            borderRadius={2}
            color="rgba(127, 135, 152, 1)"
            p={1}
            sx={{ backgroundColor: "white" }}
            textstyle="p2Paragraph"
          >
            {getFormulaValue}
          </LqdTypography>
          <Box alignItems="center" display="flex">
            <LqdCheckIcon color="rgba(106, 231, 3, 1)" size={30} />
            <LqdTypography color="rgba(106, 231, 3, 1)" textstyle="p2Paragraph">
              Formula OK!
            </LqdTypography>
          </Box>
        </Stack>
      ) : null}
      {wrongFormula && !validatingFormula ? (
        <Stack alignItems="center" direction="row" gap={1} justifyContent="center">
          <Box alignItems="center" display="flex">
            <LqdCloseIcon color="rgba(253, 0, 0, 1)" size={30} />
            <LqdTypography color="rgba(253, 0, 0, 1)" textstyle="p2Paragraph">
              Formula Incorreta!
            </LqdTypography>
          </Box>
        </Stack>
      ) : null}
      {checkNeededVariables.length > 0
        ? checkNeededVariables.map((variable, index) => {
            const variableButton = newVariableButton
              ? cloneElement(newVariableButton as JSX.Element, { id: FORMULA_KEY })
              : null;

            return (
              <Stack key={variable} sx={{ ml: 5 }}>
                <Stack>
                  <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1, mt: 2.5 }} textstyle="p2Paragraph">
                    Adicione um valor para {variable} {getFormulaTypeCallback(index)}
                  </LqdTypography>
                  <LqdSelect
                    onChange={(e) => {
                      onUpdateFunction(variable, e);
                    }}
                    options={formatFilterOptions}
                    value={func.variables[variable] as string}
                  />
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>{variableButton}</Box>
              </Stack>
            );
          })
        : null}
      <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mt: 2.5 }}>
        Resultados
      </LqdTypography>
      <Stack spacing={1.5} sx={{ ml: 5, mt: 1 }}>
        {Object.entries(resultInputs).map(([key, variable]) => (
          <SimulatorFunctionBlocksFunctionVariableInput
            currentBlock={currentBlock}
            editVariable={editVariableButton}
            func={func}
            isOpen={openSelectComponent}
            isResult={true}
            key={key}
            newOption={null}
            onBlur={onBlurFunction}
            onChange={(value) => onUpdateFunction(key, value as string, false, true)}
            options={[]}
            value={func.result[key]}
            variableData={{ key, variable }}
          />
        ))}
      </Stack>
    </>
  );
}
