import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { ToastNotification } from "@common/types/ToastNotification";
import { Box } from "@mui/material";
import {
  handleDeleteActiveNode,
  handleDuplicateActiveNode,
  handleSaveActionNode,
  handleSaveStartFlowNode,
} from "@productBuilder/utils/nodeActionsFunctions";
import React, { useEffect, useState } from "react";
import { useUpdateNodeInternals } from "react-flow-renderer";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ObjectOf } from "../../../common/types/ObjectOf";
import { activeNodeChanged, nodeUpdated, onSaveRules } from "../../productBuilderSlice";
import { ActionNodeData, ProviderNodeData, StartFlowNodeData } from "../../types/NodeData";
import { ActiveSignTypes, PeerChoice } from "../../types/NodeOptions";
import Proponent from "../../types/Proponent";
import ProviderCondition from "../../types/ProviderCondition";
import Action from "./Action";
import Provider from "./Provider";
import StartFlow from "./StartFlow/StartFlow";

export default function ConfigSidebar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateNodeInternals = useUpdateNodeInternals();
  const { productId } = useParams();

  const { activeNode, autoSave, edges, nodes, proponents } = useAppSelector((state) => state.productBuilder);
  const { groupProducts } = useAppSelector((state) => state.products);
  const { simulatorInputVariables, simulatorKpis } = useAppSelector((state) => state.simulatorBuilder);

  const [rulesShouldBeSaved, setRulesShouldBeSaved] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const product = groupProducts[productId!];

  if (!product && productId) {
    const notification: ToastNotification = {
      message: "Produto não reconhecido. Por favor, verifique seu produto e tente novamente.",
      type: "error",
    };

    dispatch(toastCalled(notification));
    navigate("/products");
  }

  // Castings de actionNode, para eliminar o boilerplate de tipagem. São o mesmo objeto.
  const activeStartFlowNode: Node<StartFlowNodeData> | null =
    (activeNode as Node<StartFlowNodeData>)?.data.type === "start_flow" ? activeNode : null;

  const activeActionNode: Node<ActionNodeData> | null =
    (activeNode as Node<ActionNodeData>)?.data.type === "action" ? activeNode : null;

  const activeProviderNode: Node<ProviderNodeData> | null =
    (activeNode as Node<ProviderNodeData>)?.data.type === "providers" ||
    (activeNode as Node<ProviderNodeData>)?.data.type === "global"
      ? activeNode
      : null;

  // ------------------------- Dados do activeNode -------------------------
  // Proponentes adicionados, caso o nó selecionado seja de de Start Flow
  const [temporaryProponents, setTemporaryProponents] = useState<Array<Proponent>>(Array.from(proponents));
  // Produto selecionado, caso o nó selecionado seja de Jump Product action
  const [selectedProduct, setSelectedProduct] = useState<string | null>(
    activeActionNode?.data.custom_data?.jumpToProduct || null
  );
  // Mensagem de warning, caso o nó selecionado seja de Warning action
  const [warningMessage, setWarningMessage] = useState(activeActionNode?.data?.custom_data?.warningMessage || "");
  // Lista de conditions, caso o nó selecionado seja de provider
  const conditions: Array<ProviderCondition> = activeProviderNode?.data.custom_data?.conditions || [];
  const [temporaryConditions, setTemporaryConditions] = useState<Array<ProviderCondition>>([...conditions]);
  // KPI selecionado, caso o nó selecionado seja de provider
  const selectedKpi: string | null = activeProviderNode?.data.custom_data?.selectedKpi || null;
  const [temporarySelectedKpi, setTemporarySelectedKpi] = useState<string | null>(selectedKpi);
  // Simulation ID (caso o active node seja um nó de simulator customizado)
  const [selectedSimulationId, setSelectedSimulationId] = useState(activeProviderNode?.data.simulation_id || "");
  // Peers ativos, para provider de assinatura de documentos
  const [activeProponents, setActiveProponents] = useState<ObjectOf<PeerChoice>>(
    activeProviderNode?.data.options?.inputs ? activeProviderNode?.data.options.inputs[0]?.choices : {}
  );
  // Tipos de assinaturas aceitas, para provider de assinatura de documentos
  const [activeSignTypes, setActiveSignTypes] = useState<ActiveSignTypes>(
    activeProviderNode?.data.options?.inputs?.[0]?.active_sign_types || {
      ecpf: false,
      idrc: false,
    }
  );

  // ------------------------------------------------------------------------

  // Exibe o dialog de save caso houver alterações
  useEffect(() => {
    // Por serem arrays, temporaryProponents e e temporaryConditions precisam de strict change verification
    const verifyChanges = {
      selectedProductChanged: selectedProduct !== (activeActionNode?.data.custom_data?.jumpToProduct || null),
      simulationIdChanged: activeProviderNode?.data.simulation_id
        ? selectedSimulationId !== activeProviderNode?.data.simulation_id
        : false,
      temporaryConditionsChanged: JSON.stringify(conditions) !== JSON.stringify(temporaryConditions),
      temporaryProponentsChanged: JSON.stringify(proponents) !== JSON.stringify(temporaryProponents),
      temporarySelectedKpiChanged: temporarySelectedKpi !== selectedKpi,
      warningMessageChanged: warningMessage !== (activeActionNode?.data.custom_data?.warningMessage || ""),
    };

    const anythingChanged = Object.values(verifyChanges).some((change) => change);

    if (anythingChanged) {
      setHasChanges(true);
    }
  }, [temporaryProponents, temporaryConditions, selectedProduct, warningMessage, temporarySelectedKpi]);

  // salva uma rule toda vez que houver auto save ativo e rulesShouldBeSaved true
  useEffect(() => {
    (async () => {
      if (rulesShouldBeSaved && autoSave) {
        await dispatch(
          onSaveRules({
            autoSave,
            edges,
            nodes,
            product,
            proponents,
            publish: false,
            simulatorKpis,
          })
        );
        setRulesShouldBeSaved(false);
      }
    })();
  }, [rulesShouldBeSaved]);

  // Atualiza o valor dos activeProponents toda vez que o activeProviderNode mudar
  useEffect(() => {
    setActiveProponents(
      activeProviderNode?.data.options.inputs?.length
        ? activeProviderNode?.data.options.inputs[0].choices
        : activeProponents
    );
  }, [activeProviderNode]);

  // Fecha a ConfigSidebar
  const onCloseSidebar = () => {
    dispatch(activeNodeChanged(null));
  };

  // Duplica o nó ativo
  const onDuplicateActiveNode = () => {
    handleDuplicateActiveNode(activeNode, hasChanges, dispatch);
  };

  const onDeleteActiveNode = () => {
    handleDeleteActiveNode(activeNode, dispatch);
  };

  // Salva as alterações de um nó de provider
  const onSaveProviderNode = () => {
    const providerNode = activeNode as Node<ProviderNodeData>;
    const updatedProviderNode: Node<ProviderNodeData> = {
      ...providerNode,
      data: {
        ...activeNode?.data,
        custom_data: {
          conditions: [...temporaryConditions],
          selectedKpi: temporarySelectedKpi,
        },
        options: {
          ...providerNode.data.options,
          inputs: providerNode.data.options.inputs?.map((input) => ({
            ...input,
            active_sign_types: {
              ecpf: activeSignTypes.ecpf,
              idrc: activeSignTypes.idrc,
            },
            choices: activeProponents,
          })),
        },
        simulation_id: selectedSimulationId,
      },
    };
    const providerIsSimulator = providerNode.data.options.simulation_type === "simulator";
    if (providerIsSimulator && selectedSimulationId) {
      updatedProviderNode.data.options.inputs = simulatorInputVariables[selectedSimulationId];
      updatedProviderNode.data.simulation_id = selectedSimulationId;
    }
    dispatch(nodeUpdated(updatedProviderNode));
  };

  const onSaveStartFlowNode = () => {
    handleSaveStartFlowNode(temporaryProponents, dispatch);
  };

  // Salva as alterações de um nó de action
  const onSaveActionNode = () => {
    handleSaveActionNode(activeNode, warningMessage, selectedProduct, dispatch);
  };

  const onSaveChanges = () => {
    if (activeActionNode?.data.action) {
      onSaveActionNode();
    } else if (activeProviderNode?.data.provider) {
      onSaveProviderNode();
    } else if (activeStartFlowNode?.data.type === "start_flow") {
      onSaveStartFlowNode();
    }
    setTimeout(() => {
      updateNodeInternals((activeNode as Node).id);
    }, 100);
    setHasChanges(false);
    setRulesShouldBeSaved(true);
  };

  const getNodeContent = () => {
    const nodeData = activeNode?.data;
    if (activeNode && nodeData) {
      if (nodeData.type === "action") {
        return (
          <Action
            activeNode={activeNode}
            onClose={onCloseSidebar}
            onDelete={onDeleteActiveNode}
            onDuplicate={onDuplicateActiveNode}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct as React.Dispatch<React.SetStateAction<string>>}
            setWarningMessage={setWarningMessage}
            type={nodeData.action}
            warningMessage={warningMessage}
          />
        );
      } else if (nodeData.type === "providers" || nodeData.type === "global" || !nodeData.type) {
        return (
          <Provider
            activeNode={activeNode}
            activeProponents={activeProponents}
            activeSignTypes={activeSignTypes}
            onClose={onCloseSidebar}
            onDelete={onDeleteActiveNode}
            onDuplicate={onDuplicateActiveNode}
            selectedSimulationId={selectedSimulationId}
            setActiveProponents={setActiveProponents}
            setActiveSignTypes={setActiveSignTypes}
            setSelectedSimulationId={setSelectedSimulationId}
            setTemporaryConditions={setTemporaryConditions}
            setTemporarySelectedKpi={setTemporarySelectedKpi}
            temporaryConditions={temporaryConditions}
            temporarySelectedKpi={temporarySelectedKpi!}
          />
        );
      } else {
        return (
          <StartFlow
            onClose={onCloseSidebar}
            setTemporaryProponents={setTemporaryProponents}
            temporaryProponents={temporaryProponents}
          />
        );
      }
    }
  };

  return activeNode ? (
    <>
      <Box
        id="lqd-config-sidebar"
        sx={{
          backgroundColor: "rgba(249, 249, 250, 1)",
          borderBottomLeftRadius: "20px",
          borderTopLeftRadius: "20px",
          boxSizing: "border-box",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          paddingBlock: "1rem 2rem",
          pb: 6,
          position: "absolute",
          pt: 4,
          px: 6,
          right: 0,
          top: 0,
          width: "440px",
          zIndex: 10,
        }}
      >
        {getNodeContent()}
      </Box>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(98, 109, 125, 1)",
          borderBottomLeftRadius: "20px",
          bottom: 0,
          display: hasChanges ? "flex" : "none",
          height: hasChanges ? "124px" : 0,
          position: "absolute",
          right: 0,
          transition: "height 0.1s",
          width: "440px",
          zIndex: 11,
        }}
      >
        <LqdTypography color="rgba(255, 255, 255, 1)" sx={{ ml: 3 }}>
          Aplicar alterações?
          <Box sx={{ mt: 1 }}>
            <LqdButton onClick={onCloseSidebar} sx={{ mr: 2 }} type="filledSecundary">
              Cancelar
            </LqdButton>
            <LqdButton onClick={onSaveChanges}>Aplicar</LqdButton>
          </Box>
        </LqdTypography>
      </Box>
    </>
  ) : null;
}
