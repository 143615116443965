import {
  LqdButton,
  LqdPlusIcon,
  LqdRightIcon,
  LqdSearch,
  LqdSearchIcon,
  LqdTooltip,
  LqdTypography,
} from "@/liquid-components/src";
import useWindowSize from "@common/hooks/useWindowSize";
import { ObjectOf } from "@common/types/ObjectOf";
import { Box, debounce, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { useClickOutside } from "../hooks/useClickOutside";
import BreadcrumbPath from "./BreadcrumbPath";

type RouteSearchHeaderProps = {
  archiveComponent: JSX.Element | null;
  breadcrumbContent?: Array<string>;
  buttonName?: string;
  filterComponent: JSX.Element | null;
  labelValue: string;
  onButtonClick: (value: boolean) => void;
  placeholder: string;
  routeHref?: string;
  setSearchInputValue: Dispatch<SetStateAction<string>>;
  specialRender?: JSX.Element | null;
  tooltipBody: string;
  tooltipHeader: string;
};

export default function RouteSearchHeader(props: RouteSearchHeaderProps) {
  const {
    archiveComponent,
    breadcrumbContent,
    buttonName,
    filterComponent,
    labelValue,
    onButtonClick,
    placeholder,
    routeHref,
    setSearchInputValue,
    specialRender,
    tooltipBody,
    tooltipHeader,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowSize();

  const { currentUser } = useAppSelector((state) => state.users);
  const { board } = useAppSelector((state) => state.common);

  const [showMobileSearchInput, setShowMobileSearchInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = useRef<HTMLDivElement>(null);
  useClickOutside(searchInputRef, () => setShowMobileSearchInput(false));

  const onLabelClick = () => {
    if (routeHref) {
      navigate(routeHref);
    }
  };

  const renderButtonValidation = () => {
    switch (location.pathname) {
      case `/${board}`:
        return currentUser?.userRole !== "viewer" ? "inline-flex" : "none";
      case "/products":
      case "/automations":
      default:
        return currentUser?.userRole === "super" || currentUser?.userRole === "admin" ? "inline-flex" : "none";
    }
  };

  const paddingLeftLookup: ObjectOf<{ lg: number; md: number; xs: number }> = {
    "/automations": { lg: 49, md: 4, xs: 4 },
    "/products": { lg: 44, md: 3, xs: 2 },
    default: { lg: 11, md: 7, xs: 3 },
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInputValue(value);
    }, 300),
    []
  );

  const onChangeSearch = (value: string) => {
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "80px",
        justifyContent: "space-between",
        pl: paddingLeftLookup[location.pathname] || paddingLeftLookup["default"],
        pr: { lg: 9, md: 4, xs: 3 },
      }}
    >
      <Stack alignItems="center" direction={{ md: "row", xs: "column" }} spacing={{ md: "10px", xs: "4px" }}>
        <Stack alignItems={{ md: "center", xs: "flex-start" }} direction={{ md: "row", xs: "column" }} spacing={0.5}>
          <Stack alignItems="center" direction="row">
            <LqdTypography
              onClick={onLabelClick}
              sx={{
                color: breadcrumbContent?.length ? "rgba(127, 135, 152, 1)" : "rgba(33, 36, 42, 1)",
                cursor: routeHref && routeHref !== "/automations" ? "pointer" : "auto",
                display: { md: "block", xs: showMobileSearchInput ? "none" : "block" },
              }}
              textstyle="h5Headline"
            >
              {labelValue}
            </LqdTypography>
            {breadcrumbContent?.length ? (
              <Box sx={{ height: "24px", width: "24px" }}>
                <LqdRightIcon />
              </Box>
            ) : null}
          </Stack>
          {breadcrumbContent?.length
            ? breadcrumbContent.map((item) => (
                <BreadcrumbPath item={item} key={item} showMobileSearchInput={showMobileSearchInput} />
              ))
            : null}
        </Stack>
      </Stack>
      {specialRender || (
        <Stack
          alignItems="center"
          direction="row"
          spacing={1.5}
          sx={{ justifyContent: "flex-end", width: { md: "fit-content" } }}
        >
          {placeholder ? (
            <Box
              ref={searchInputRef}
              sx={{
                display: { md: "flex", xs: showMobileSearchInput ? "flex" : "none" },
                width: { md: "350px", xs: "100%" },
              }}
            >
              <LqdSearch
                onChange={({ target: { value } }) => onChangeSearch(value)}
                placeholder={placeholder}
                value={searchValue}
              />
            </Box>
          ) : null}
          <Stack
            alignItems="center"
            justifyContent="center"
            onClick={() => setShowMobileSearchInput(true)}
            sx={{
              backgroundColor: "rgba(240, 241, 243, 1)",
              borderRadius: "50%",
              display: { md: "none", xs: showMobileSearchInput ? "none" : "flex" },
              height: "32px",
              width: "32px",
            }}
          >
            <LqdSearchIcon color="rgba(127, 135, 152, 1)" size={24} />
          </Stack>
          {archiveComponent}
          {filterComponent}
          {!params.automationId && buttonName ? (
            <LqdTooltip
              componentsProps={{ tooltip: { sx: { maxWidth: "180px" } } }}
              placement="left"
              titlebody={tooltipBody}
              titleheader={tooltipHeader}
            >
              <LqdButton
                buttonsize={width === "xs" ? "small" : "medium"}
                id="lqd-header-add-button"
                onClick={() => (onButtonClick ? onButtonClick(true) : () => {})}
                startIcon={<LqdPlusIcon />}
                sx={{ display: renderButtonValidation(), height: "48px" }}
              >
                {buttonName}
              </LqdButton>
            </LqdTooltip>
          ) : null}
        </Stack>
      )}
    </Box>
  );
}
