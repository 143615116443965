import { Analysis } from "../types/Analysis";
import { AnalysisRaw } from "../types/AnalysisRaw";

export const formatRawAnalysis = (rawAnalysis: AnalysisRaw): Analysis => ({
  archived: rawAnalysis.analysis.archived,
  archivedAt: rawAnalysis.analysis.archived_at ? rawAnalysis.analysis.archived_at * 1000 : null,
  archivedBy: rawAnalysis.analysis.archived_by || null,
  createdAt: rawAnalysis.created_at * 1000,
  createdBy: rawAnalysis.analysis.created_by,
  finished: rawAnalysis.analysis.finished,
  finishedAt: rawAnalysis.analysis.finished_at ? rawAnalysis.analysis.finished_at * 1000 : null,
  finishedBy: rawAnalysis.analysis.finished_by || null,
  finished_status: rawAnalysis.analysis?.finished_status,
  hasPendencies: rawAnalysis.analysis.has_pendency,
  humanInteraction: rawAnalysis.analysis.human_interaction,
  humanInteractionAt: rawAnalysis.analysis.human_interaction_at
    ? rawAnalysis.analysis.human_interaction_at * 1000
    : null,
  humanInteractionBy: rawAnalysis.analysis.human_interaction_by || null,
  id: rawAnalysis.id,
  lastChangeBy: rawAnalysis.last_change_by,
  peers: rawAnalysis.peers?.map((rawPeer) => ({
    birthday: rawPeer.birthday,
    document: rawPeer.document,
    fullName: rawPeer.full_name || "Nome não encontrado",
    passId: rawPeer.pass_id,
    peer: rawPeer.peer,
  })),
  productId: rawAnalysis.analysis.product_id,
  productName: rawAnalysis.analysis.product_name,
  solvedPendency: rawAnalysis.analysis.solved_pendency,
  status: rawAnalysis.analysis.status,
  tags: rawAnalysis.analysis?.tags,
  updatedAt: rawAnalysis.updated_at * 1000,
});
