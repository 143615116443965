import {
  LqdCloseIcon,
  LqdColumnIcon,
  LqdIconButton,
  LqdListIcon,
  LqdLogoutIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { Avatar, Box, Card, CardHeader, Drawer, ListItem, ListItemButton, ListItemIcon, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { CurrentUser } from "../../users/types/CurrentUser";

type MobileSystemHeaderProps = {
  handleLogoutClick: () => void;
  setShowDrawer: (value: boolean) => void;
  showDrawer: boolean;
};

export default function MobileSystemHeader(props: MobileSystemHeaderProps) {
  const { handleLogoutClick, setShowDrawer, showDrawer } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { board } = useAppSelector((state) => state.common);

  const currentUser = useAppSelector((state) => state.users.currentUser);
  const userName = currentUser?.fullname;
  const userRole =
    currentUser?.userRole === "super" ? "Superadmin" : capitalizeString((currentUser as CurrentUser).userRole);

  return (
    <Drawer anchor="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
      <Box
        sx={{
          ".MuiListItem-root": { border: "none" },
          ".MuiListItemButton-root": { pl: 0 },
          ".MuiListItemIcon-root": { minWidth: 0, mr: 2 },
          backgroundColor: theme.palette.primary.main,
          boxSizing: "border-box",
          height: "100vh",
          width: { sm: "400px", xs: "100vw" },
        }}
      >
        <Card elevation={0} sx={{ backgroundColor: "transparent", color: "rgba(255, 255, 255, 1)" }}>
          <CardHeader
            action={
              <LqdIconButton onClick={() => setShowDrawer(false)} round="true">
                <LqdCloseIcon />
              </LqdIconButton>
            }
            avatar={
              <Avatar sx={{ backgroundColor: "rgba(65, 70, 103, 0.4)" }}>
                <LqdTypography color="rgba(255, 255, 255, 1)" textstyle="p2Paragraph">
                  {stringInitials(currentUser?.fullname || "").toUpperCase()}
                </LqdTypography>
              </Avatar>
            }
            subheader={
              <LqdTypography color="rgba(255, 255, 255, 1)" textstyle="c1Caption">
                {userRole}
              </LqdTypography>
            }
            sx={{ ".MuiCardHeader-avatar": { mr: 1 }, px: 3, py: 3 }}
            title={<LqdTypography color="rgba(255, 255, 255, 1)">{userName}</LqdTypography>}
          />
        </Card>
        <LqdTypography
          color="rgba(255, 255, 255, 1)"
          sx={{ borderBottom: "1px solid rgba(56, 61, 70, 1)", mb: 0.5, ml: 3, pb: 0.5, width: "90%" }}
        >
          Menu
        </LqdTypography>
        {pathname !== "/home" && !pathname.includes("/play") ? (
          <>
            <ListItem
              disablePadding
              onClick={() => {
                navigate(`/${board}`);
                setShowDrawer(false);
              }}
              sx={{ ml: 3 }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LqdColumnIcon color="rgba(255, 255, 255, 1)" />
                </ListItemIcon>
                <LqdTypography color="rgba(255, 255, 255, 1)" textstyle="p2Paragraph">
                  Board
                </LqdTypography>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                navigate("/products");
                setShowDrawer(false);
              }}
              sx={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)", ml: 3, py: 1 }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LqdListIcon color="rgba(255, 255, 255, 1)" />
                </ListItemIcon>
                <LqdTypography color="rgba(255, 255, 255, 1)" textstyle="p2Paragraph">
                  Produtos
                </LqdTypography>
              </ListItemButton>
            </ListItem>
            <Box sx={{ borderBottom: "1px solid rgba(56, 61, 70, 1)", ml: 3, width: "90%" }} />
          </>
        ) : null}
        <ListItem disablePadding onClick={handleLogoutClick} sx={{ ml: 3, pt: 0.5 }}>
          <ListItemButton>
            <ListItemIcon>
              <LqdLogoutIcon color="rgba(255, 255, 255, 1)" />
            </ListItemIcon>
            <LqdTypography color="rgba(255, 255, 255, 1)" textstyle="p2Paragraph">
              Sair
            </LqdTypography>
          </ListItemButton>
        </ListItem>
      </Box>
    </Drawer>
  );
}
