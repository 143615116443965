import {
  LqdBarsIcon,
  LqdIconButton,
  LqdLink,
  LqdLogoutIcon,
  LqdSettingsIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { ArrowDropDown } from "@mui/icons-material";
import { Avatar, Box, Card, CardHeader, Divider, Grid, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onAnalysisDetailsModalClosed } from "../../analysisDetails/analysisDetailsSlice";
import { analysisArchivingToggled, onArchiveSelectedAnalysis } from "../../board/boardSlice";
import { CurrentUser } from "../../users/types/CurrentUser";
import { onCurrentUserCleared } from "../../users/usersSlice";
import { dialogCalled, dialogLoaded, toastCalled } from "../commonSlice";
import useAuth from "../hooks/useAuth";
import { ToastNotification } from "../types/ToastNotification";
import { useErrorHandler } from "../utils/useErrorHandler";
import LiquidLogo from "./LiquidLogo";
import MobileSystemHeader from "./MobileSystemHeader";

export default function SystemHeader() {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { selectedAnalysisToArchive } = useAppSelector((state) => state.board);
  const { currentUser } = useAppSelector((state) => state.users);
  const { board } = useAppSelector((state) => state.common);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileView, setMobileView] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 900;
      setMobileView(isMobile);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const selectedTab = pathname.split("/")[1];
  const open = Boolean(menuAnchorEl);
  const handleClose = () => setMenuAnchorEl(null);

  const userNameFormatted = currentUser?.fullname;
  const userRole =
    currentUser?.userRole === "super" ? "Superadmin" : capitalizeString((currentUser as CurrentUser).userRole);

  const onConfirmSignOut = () => {
    auth.signOut();
    dispatch(onCurrentUserCleared());
    window.location.reload();
  };

  const onSignOutClick = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: onConfirmSignOut, title: "Sair" }],
        body: "Você está prestes a sair da aplicação. Deseja continuar?",
        title: "Atenção",
        type: "default",
      })
    );
  };

  const onArchiveClick = async () => {
    if (selectedAnalysisToArchive) {
      try {
        dispatch(dialogLoaded(true));
        await dispatch(onArchiveSelectedAnalysis(selectedAnalysisToArchive));
        dispatch(analysisArchivingToggled());
        dispatch(dialogCalled(null));

        const notification: ToastNotification = {
          message: "Conexão arquivada com sucesso. Visualize as conexões arquivadas no filtro da Board.",
          type: "success",
        };

        dispatch(toastCalled(notification));
      } catch (error) {
        handleLiquidErrors(error);
      }
    }
  };

  const onNavigateToProducts = () => {
    dispatch(dialogCalled(null));
    navigate("/products");
  };

  const handleHomeClick = () => {
    navigate("/home");
  };

  const handleBoardClick = (event: MouseEvent) => {
    event.preventDefault();
    if (pathname !== `/${board}`) {
      dispatch(onAnalysisDetailsModalClosed());
    }
    navigate(`/${board}`);
  };

  const handleProductsClick = () => {
    if (selectedAnalysisToArchive && Object.keys(selectedAnalysisToArchive)) {
      dispatch(
        dialogCalled({
          actions: [
            {
              onClick: onNavigateToProducts,
              title: "Continuar sem arquivar",
            },
            { onClick: onArchiveClick, title: "Arquivar" },
          ],
          body: "Se mudar de página, vai perder a seleção. Tem certeza que deseja fazer isso?",
          title: "Você ainda tem conexões selecionadas",
          type: "default",
        })
      );
    } else {
      navigate("/products");
    }
  };

  const handleAutomationsClick = () => {
    navigate("/automations");
  };

  return (
    <>
      <Box
        sx={{
          backdropFilter: "blur(15px)",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(250, 250, 250, 0.7)",
          borderBottom: "0.5px solid rgba(127, 135, 152, 0.08)",
          maxHeight: "96px",
          minHeight: "96px",
          top: currentUser?.userRole === "super" ? 60 : 0,
        }}
      >
        <Grid container sx={{ alignItems: "center", display: "flex", height: "96px", px: { lg: 5, sm: 2, xs: 3 } }}>
          <Grid item sm={3} xs={6}>
            <Box sx={{ width: "fit-content" }}>
              <LqdLink href="/home" onClick={handleHomeClick} sx={{ "&:hover": { cursor: "pointer" } }}>
                <LiquidLogo sx={{ height: "40px" }} />
              </LqdLink>
            </Box>
          </Grid>
          <Grid item sx={{ display: { md: "block", xs: "none" } }} xs={6}>
            {!mobileView && pathname !== "/home" && !pathname.includes("/play") ? (
              <Tabs sx={{ ".MuiTabs-indicator": { top: 0 } }} value={selectedTab}>
                <Tab label="Board" onClick={handleBoardClick} sx={{ py: "39px" }} value="board" />
                <Tab label="Produtos" onClick={handleProductsClick} sx={{ py: "39px" }} value="products" />
                <Tab label="Automações" onClick={handleAutomationsClick} sx={{ py: "39px" }} value="automations" />
              </Tabs>
            ) : null}
          </Grid>
          <Grid item md={3} sm={8.8} sx={{ display: "flex", justifyContent: "flex-end" }} xs={6}>
            <LqdIconButton
              onClick={() => setShowDrawer(true)}
              sx={{ display: { md: "none", xs: "flex" } }}
              type="ghostIcon"
            >
              <LqdBarsIcon />
            </LqdIconButton>
            <Card elevation={0} sx={{ backgroundColor: "transparent", display: { md: "flex", xs: "none" } }}>
              <CardHeader
                action={
                  <LqdIconButton
                    id="system-header-menu-button"
                    onClick={(event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget)}
                    type="ghostIcon"
                  >
                    <ArrowDropDown />
                  </LqdIconButton>
                }
                avatar={
                  <Avatar sx={{ backgroundColor: "rgba(191, 196, 205, 0.16)" }}>
                    <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="p2Paragraph">
                      {stringInitials(currentUser?.fullname || "").toUpperCase()}
                    </LqdTypography>
                  </Avatar>
                }
                subheader={
                  <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="c1Caption">
                    {userRole}
                  </LqdTypography>
                }
                sx={{ ".MuiCardHeader-avatar": { mr: 1.5 } }}
                title={userNameFormatted}
              />
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                id="basic-menu"
                MenuListProps={{
                  sx: {
                    backgroundColor: "rgba(249, 249, 250, 1)",
                    color: "rgba(101, 110, 127, 1)",
                    padding: 0,
                    paddingBottom: 0,
                  },
                }}
                onClose={handleClose}
                open={open}
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: "12px",
                      boxShadow: "0px 25px 60px -15px rgba(16, 24, 40, 0.20)",
                      mt: 2.5,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {(currentUser?.userRole === "super" || currentUser?.userRole === "admin") &&
                pathname !== "/home" &&
                pathname !== "/play" ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        navigate("/config");
                        handleClose();
                      }}
                      sx={{
                        ":hover": { backgroundColor: "rgba(236, 238, 240, 1)", color: "rgba(101, 110, 127, 1)" },
                        height: "48px",
                        px: 3,
                        width: "220px",
                      }}
                    >
                      <LqdSettingsIcon />
                      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textstyle="buttonM">
                        Configurações
                      </LqdTypography>
                    </MenuItem>
                    <Divider sx={{ borderColor: "rgba(212, 215, 220, 1)", margin: "0 !important" }} />
                  </>
                ) : null}
                <MenuItem
                  onClick={onSignOutClick}
                  sx={{
                    ":hover": { backgroundColor: "rgba(236, 238, 240, 1)", color: "rgba(101, 110, 127, 1)" },
                    color: "rgba(101, 110, 127, 1)",
                    height: "48px",
                    px: 3,
                    width: "220px",
                  }}
                >
                  <LqdLogoutIcon />
                  <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textstyle="buttonM">
                    Sair
                  </LqdTypography>
                </MenuItem>
              </Menu>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <MobileSystemHeader handleLogoutClick={onSignOutClick} setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
    </>
  );
}
