import { updateUserEmail } from "@analysis/api/UpdateUserEmail";
import { updateUserPhone } from "@analysis/api/UpdateUserPhone";
import { PeerData } from "@analysis/types/AnalysisDetails/PeerData/PeerData";
import { ObjectOf } from "@common/types/ObjectOf";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedDocument } from "./types/SelectedDocument";

interface UploadDocumentsState {
  selectedCity: null | string;
  selectedDocument: SelectedDocument | null;
  selectedPeer: PeerData | null;
  selectedRegistry: null | string;
  selectedState: null | string;
}

const initialState: UploadDocumentsState = {
  selectedCity: null,
  selectedDocument: null,
  selectedPeer: null,
  selectedRegistry: null,
  selectedState: null,
};

export const onUpdateUserContactData = createAsyncThunk(
  "uploadDocuments/onUpdateUserContactData",
  async ({ analysisId, data }: { analysisId: string; data: { passId: string; phone: string } }) => {
    await updateUserPhone(analysisId, data);

    return data.phone;
  }
);

export const onUpdateUserContactEmail = createAsyncThunk(
  "uploadDocuments/onUpdateUserContactEmail",
  async ({ analysisId, data }: { analysisId: string; data: { email: string; passId: string } }) => {
    await updateUserEmail(analysisId, data);

    return data.email;
  }
);

const uploadDocumentsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(onUpdateUserContactData.fulfilled, (state, action) => {
        state.selectedPeer!.contact!.phone = action.payload;
      })
      .addCase(onUpdateUserContactEmail.fulfilled, (state, action) => {
        state.selectedPeer!.contact!.email = action.payload;
      });
  },
  initialState,
  name: "uploadDocuments",
  reducers: {
    onSelectCity: (state, action: PayloadAction<string>) => {
      state.selectedCity = action.payload;
    },
    onSelectDocument: (state, action: PayloadAction<SelectedDocument>) => {
      state.selectedDocument = action.payload;
    },
    onSelectPeer: (state, action: PayloadAction<{ currentPeer: PeerData; peers: ObjectOf<PeerData> } | null>) => {
      if (action.payload === null) {
        state.selectedPeer = null;
        return;
      }

      const selectedPeerDocument = action.payload.currentPeer?.document;
      const currentPeerContact = action.payload.peers[selectedPeerDocument!]?.contact;

      state.selectedPeer = {
        ...action.payload.currentPeer!,
        contact: {
          email: currentPeerContact?.email || state.selectedPeer?.contact?.email || "",
          phone: currentPeerContact?.phone || state.selectedPeer?.contact?.phone || "",
        },
      };
    },
    onSelectRegistry: (state, action: PayloadAction<string>) => {
      state.selectedRegistry = action.payload;
    },
    onSelectState: (state, action: PayloadAction<string>) => {
      state.selectedState = action.payload;
    },
  },
});

export const { onSelectCity, onSelectDocument, onSelectPeer, onSelectRegistry, onSelectState } =
  uploadDocumentsSlice.actions;

export default uploadDocumentsSlice.reducer;
