import { LqdTypography, LqdWarningIcon } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import React from "react";

type SmallDialogHeaderProps = {
  body: string;
  children?: React.ReactNode;
  title: string;
};

export default function SmallDialogHeader(props: SmallDialogHeaderProps) {
  const { body, children, title } = props;

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "rgba(240, 241, 243, 1)", borderRadius: "50%", height: "48px", mb: 2.5, width: "48px" }}
      >
        <LqdWarningIcon color="rgba(127, 135, 152, 1)" />
      </Stack>
      <LqdTypography color="rgba(23, 23, 23, 1)" sx={{ mb: 1 }} textstyle="h5Headline">
        {title}
      </LqdTypography>
      <LqdTypography color="rgba(115, 115, 115, 1)" textstyle="p2Paragraph">
        {body}
      </LqdTypography>
      {children ? <Box>{children}</Box> : null}
    </>
  );
}
