import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import SignInRequest from "../signin/types/SignInRequest";
import { SmallDialogConfig } from "./components/SmallDialog/SmallDialog";
import { ToastNotification } from "./types/ToastNotification";
import { REPOSITORY_TOKEN } from "./utils/apiUtils";

interface CommonState {
  appCommit: string;
  appVersion: string;
  board: "board" | "registryBoard";
  dialogContent: SmallDialogConfig;
  dialogDateFieldEnd: string;
  dialogDateFieldStart: string;
  dialogErrorMessage: string;
  dialogFieldValue: Array<string> | string;
  dialogLoading: boolean;
  dialogShowState: boolean;
  showTermsAndConditionsPopUp: boolean;
  termsAndConditionLoginForm: SignInRequest | null;
  toastNotification: ToastNotification & { title?: string };
}

const initialState: CommonState = {
  appCommit: "",
  appVersion: "",
  board: "board",
  dialogContent: { actions: [], blank: false, body: "", title: "", type: "default" },
  dialogDateFieldEnd: "",
  dialogDateFieldStart: "",
  dialogErrorMessage: "",
  dialogFieldValue: "",
  dialogLoading: false,
  dialogShowState: false,
  showTermsAndConditionsPopUp: false,
  termsAndConditionLoginForm: null,
  toastNotification: {
    duration: 4000,
    horizontal: "right",
    message: "",
    title: "",
    type: null,
    vertical: "bottom",
  },
};

const toastTitle = (type: "error" | "info" | "success" | "warning" | null) => {
  switch (type) {
    case "error":
      return "Algo deu errado!";
    case "info":
      return "Ocorreu alguma instabilidade...";
    case "success":
      return "Sucesso!";
    case "warning":
      return "Opa, algo aconteceu...";
    default:
      return "";
  }
};

/* Busca a versão atual da aplicação no GitHub. */
export const onLoadAppVersion = createAsyncThunk("common/onLoadAppVersion", async () => {
  try {
    const { data } = await axios("https://api.github.com/repos/liquidpass/frontend-app/tags", {
      headers: { Authorization: `Bearer ${REPOSITORY_TOKEN}` },
    });

    const commit = data[0].commit.sha.slice(0, 7);
    const version = data[0].name;

    return { commit, version };
  } catch (error) {
    const errorData = error as AxiosError;
    const data = errorData.response?.data;
    console.log("error: ", data);
  }
});

const commonSlice = createSlice({
  extraReducers: (builder) =>
    builder.addCase(onLoadAppVersion.fulfilled, (state, action) => {
      const { commit, version } = action.payload!;

      state.appCommit = commit;
      state.appVersion = version;
    }),
  initialState,
  name: "common",
  reducers: {
    dialogCalled(state, action: PayloadAction<SmallDialogConfig | null>) {
      const config = action.payload;
      state.dialogShowState = true;

      if (!config) {
        state.dialogErrorMessage = "";
        state.dialogFieldValue = "";
        state.dialogLoading = false;
        state.dialogShowState = false;
      } else {
        state.dialogContent = config;
      }
    },
    dialogDateFieldChanged(state, action: PayloadAction<{ type: string; value: string }>) {
      if (action.payload.type === "end") {
        state.dialogDateFieldEnd = action.payload.value;
      } else {
        state.dialogDateFieldStart = action.payload.value;
      }
    },
    dialogErrorMessageChanged(state, action: PayloadAction<string>) {
      state.dialogErrorMessage = action.payload;
    },
    dialogFieldValueChanged(state, action: PayloadAction<string | Array<string>>) {
      state.dialogFieldValue = action.payload;
    },
    dialogLoaded(state, action: PayloadAction<boolean>) {
      state.dialogLoading = action.payload;
    },
    setBlank(state, action: PayloadAction<boolean>) {
      state.dialogContent.blank = action.payload;
    },
    switchBoard(state, action: PayloadAction<"board" | "registryBoard">) {
      state.board = action.payload;
    },
    termsCalled(state, action: PayloadAction<boolean>) {
      state.showTermsAndConditionsPopUp = action.payload;
    },
    termsFormChanged(state, action: PayloadAction<SignInRequest | null>) {
      state.termsAndConditionLoginForm = action.payload;
    },
    toastCalled(
      state,
      action: PayloadAction<{
        customTitle?: string;
        message: string;
        type: "error" | "info" | "success" | "warning" | null;
      }>
    ) {
      const { customTitle, message, type } = action.payload;
      const title = customTitle || toastTitle(type);
      const tMessage =
        type === "info"
          ? "Parece que nosso servidor não está respondendo. Tente novamente em alguns instantes."
          : message;

      state.toastNotification = { message: tMessage, title, type };
    },
    toastReseted(state) {
      state.toastNotification = { ...initialState.toastNotification };
    },
    toggleOption(state, action: PayloadAction<1 | 2>) {
      state.dialogContent.selectedOption = action.payload;
    },
  },
});

export const {
  dialogCalled,
  dialogDateFieldChanged,
  dialogErrorMessageChanged,
  dialogFieldValueChanged,
  dialogLoaded,
  setBlank,
  switchBoard,
  termsCalled,
  termsFormChanged,
  toastCalled,
  toastReseted,
  toggleOption,
} = commonSlice.actions;

export default commonSlice.reducer;
