import { LqdDeleteIcon, LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { operatorMapping } from "@simulatorBuilder/utils/compareOperators";
import { Fragment } from "react";
import { useAppSelector } from "../../../store";
import { ObjectOf } from "../../common/types/ObjectOf";
import { SimulatorFunctionBlock } from "../types/SimulatorFunctionBlock";
import { SimulatorFunctionTemplate } from "../types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "../types/SimulatorVariable";
import { SimulatorVariableTemplateType, SimulatorVariableTypeTemplate } from "../types/SimulatorVariableTypeTemplate";

type ValidationResult = ObjectOf<Array<JSX.Element | string>>;

type SimulatorBuilderValidatorProps = {
  blockId: number;
  blocks: Array<SimulatorFunctionBlock>;
};

export default function SimulatorBuilderValidator(props: SimulatorBuilderValidatorProps) {
  const { blockId, blocks } = props;

  const { functionTemplates, simulatorData, variableTypes } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;
  const variables = simulator.variables || [];

  const boldLabel = (string: JSX.Element | string) => (
    <LqdTypography color="rgba(118, 11, 70, 1)" component="span" sx={{ fontWeight: 700 }} textstyle="c1Caption">
      {string}
    </LqdTypography>
  );

  const bulletPointField = (field: JSX.Element | string) => (
    <Stack display="inline" flexDirection="row" sx={{ ml: 0.5 }}>
      {field}
    </Stack>
  );

  const validateUnavailableVariables = (variables: Array<SimulatorVariable>): ValidationResult => {
    const availableVariables = new Set(variables.map((variable) => variable.name));
    const availableBlockIds = new Set(blocks.map((block) => block.block_id));
    const availableTypes: Set<string> = new Set(variableTypes.map((type) => type.value));

    const conditionalOperators = new Set(Object.values(operatorMapping));

    const validationResult: ValidationResult = blocks.reduce((result, block) => {
      const unavailableVariablesSet = new Set(
        block.functions
          .flatMap((func) => {
            const varNames = Object.values(func.variables);

            if (func.function_name === "calculate_formulas") {
              const removeFormulaVar = delete func.variables["formula_string"]; // formula is not a variable

              const formatedVarNames = Object.values(removeFormulaVar);
              return formatedVarNames;
            }

            if (func.function_name === "jump_conditional") {
              return varNames.filter((varName) => !["block_true", "block_false"].includes(varName as string));
            }

            return varNames;
          })
          .filter((variableName): variableName is string => typeof variableName === "string")
          .filter((variableName) => {
            const mappedOperator = operatorMapping[variableName.toLowerCase()];

            return (
              !availableVariables.has(variableName) &&
              !availableBlockIds.has(Number(variableName)) &&
              !availableTypes.has(variableName) &&
              !(mappedOperator && conditionalOperators.has(mappedOperator))
            );
          })
      );

      if (unavailableVariablesSet.size > 0) {
        result[block.block_id] = Array.from(unavailableVariablesSet);
      }

      return result;
    }, {} as ValidationResult);

    return validationResult;
  };

  const validateMissingFields = (
    functionTemplates: Array<SimulatorFunctionTemplate>,
    variables: Array<SimulatorVariable>,
    variableTypes: Array<SimulatorVariableTypeTemplate>
  ): ValidationResult => {
    const validationResult: ValidationResult = blocks.reduce((result, block) => {
      const missingFields: Array<JSX.Element> = [];

      block.functions.forEach((func) => {
        const functionTemplate = functionTemplates.find((template) => template.function_name === func.function_name);
        const functionName = functionTemplate?.function_label || "Template não encontrado.";

        Object.keys(functionTemplate?.variables || {}).forEach((varName) => {
          if (func.variables[varName] == null) {
            const validationMessage = functionTemplate?.variables[varName].validation_message;
            validationMessage
              ? missingFields.push(
                  bulletPointField(
                    <>
                      {boldLabel(functionName)}: {validationMessage}
                    </>
                  )
                )
              : missingFields.push(
                  bulletPointField(
                    <>
                      {boldLabel(functionName)}: variável {boldLabel(varName)} não preenchida.
                    </>
                  )
                );
          }

          const functionVariableTypes = functionTemplate?.variables[varName].types || [];
          const currentVariable = variables.find((variable) => variable.name === func.variables[varName]);

          if (
            currentVariable &&
            currentVariable.type &&
            !functionVariableTypes.includes(currentVariable.type as SimulatorVariableTemplateType)
          ) {
            const currentVariableTypeLabel = variableTypes.find((type) => type.value === currentVariable.type)?.label;
            const possibleVariableTypes = functionVariableTypes.map(
              (type) => variableTypes.find((t) => t.value === type)?.label
            );

            missingFields.push(
              bulletPointField(
                <>
                  {boldLabel(functionName)}: variável {boldLabel(varName)} deve ser do tipo "
                  {possibleVariableTypes.map((type, index) => (
                    <Fragment key={type}>
                      {boldLabel(type!)}
                      {index < possibleVariableTypes.length - 1 ? `", ou "` : `" `}
                    </Fragment>
                  ))}
                  (tipo atual: "{boldLabel(currentVariableTypeLabel!)}").
                </>
              )
            );
          }
        });

        if (func.function_name.includes("multiple")) {
          const variableCount = ["a", "b", "c", "d"].filter((varName) => !!func.variables[varName]).length;
          if (variableCount < 2) {
            missingFields.push(bulletPointField(<>{boldLabel(functionName)}: deve ter no mínimo duas variáveis.</>));
          }
        }

        if (func.function_name === "jump") {
          if (Number(func.variables.block_id) <= block.block_id) {
            missingFields.push(
              bulletPointField(
                <>
                  {boldLabel(functionName)}: bloco de destino não pode ser inferior ao bloco atual (loop na política).
                </>
              )
            );
          }
        }

        if (func.function_name === "jump_conditional") {
          if (Number(func.variables.block_true) <= block.block_id) {
            missingFields.push(
              bulletPointField(
                <>
                  {boldLabel(functionName)}: bloco de destino (verdadeiro) não pode ser inferior ao bloco atual (loop na
                  política).
                </>
              )
            );
          }
          if (Number(func.variables.block_false) <= block.block_id) {
            missingFields.push(
              bulletPointField(
                <>
                  {boldLabel(functionName)}: bloco de destino (falso) não pode ser inferior ao bloco atual (loop na
                  política).
                </>
              )
            );
          }
        }
      });

      if (missingFields.length > 0) {
        result[block.block_id] = missingFields;
      }

      return result;
    }, {} as ValidationResult);

    return validationResult;
  };

  const validationResult = validateUnavailableVariables(variables);
  const missingFieldsResult = validateMissingFields(functionTemplates, variables, variableTypes);

  const unavailableVariables = validationResult[blockId] || [];
  const missingFields = missingFieldsResult[blockId] || [];

  const hasErrors = unavailableVariables.length > 0 || missingFields.length > 0;

  return hasErrors ? (
    <Stack
      flexDirection="row"
      gap={1}
      sx={{ backgroundColor: "rgba(255, 244, 244, 1)", borderRadius: 5, px: 2.5, py: 3 }}
    >
      <Stack sx={{ pr: 1 }}>
        <LqdDeleteIcon color="rgba(118, 11, 70, 1)" size={20} />
      </Stack>
      <Stack>
        {unavailableVariables.map((variable, index) => (
          <Stack flexDirection="row" key={index}>
            <LqdTypography color="rgba(118, 11, 70, 1)" key={index} sx={{ mr: 1 }} textstyle="c1Caption">
              •
            </LqdTypography>
            <LqdTypography color="rgba(118, 11, 70, 1)" textstyle="c1Caption">
              A variável {boldLabel(variable)} não está mais disponível.
            </LqdTypography>
          </Stack>
        ))}
        {missingFields.map((field, index) => (
          <LqdTypography color="rgba(118, 11, 70, 1)" key={index} textstyle="c1Caption">
            • {field}
          </LqdTypography>
        ))}
      </Stack>
    </Stack>
  ) : null;
}
