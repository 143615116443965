import {
  LqdButton,
  LqdDuplicateIcon,
  LqdEditIcon,
  LqdEllipsisIcon,
  LqdFunctionIcon,
  LqdIconButton,
  LqdMenu,
  LqdMenuItem,
  LqdSelect,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { ObjectOf } from "@common/types/ObjectOf";
import { capitalizeString } from "@common/utils/capitalize";
import { Box, Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { simulatorBuilderVariableSelected } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import formatVariableName from "@simulatorBuilder/utils/formatVariableName";
import { MouseEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { SimulatorFunction } from "../../../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../types/SimulatorVariableTypeTemplate";
import { SimulatorVariableTypes } from "../../../types/SimulatorVariableTypes";
import InputsRenderer from "./FunctionInputs/SimulatorFunctionBlocksFunctionInputsRenderer";

type SimulatorFunctionBlocksFunctionCardFormProps = {
  blockId: number;
  blocks: Array<SimulatorFunctionBlock>;
  createResultVariables: () => Array<SimulatorVariable>;
  func: SimulatorFunction;
  functionMethods: { add: () => void; delete: (index: number) => void; duplicate: (index: number) => void };
  setBlockName: (name: string) => void;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setOpenModal: (params: { functionId: number; functionKey: string; view: SimulatorFunctionBlocksViews }) => void;
};

export default function SimulatorFunctionBlocksFunctionCardForm(props: SimulatorFunctionBlocksFunctionCardFormProps) {
  const { blockId, blocks, createResultVariables, func, functionMethods, setBlockName, setBlocks, setOpenModal } =
    props;

  const dispatch = useAppDispatch();
  const { comparatorVariables, mathComparatorVariables, typeComparatorVariables } = useSimulatorBuilder();

  const { functionTemplates, initialSimulatorState, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;
  const simulatorInitialState = initialSimulatorState!;

  const [openSelectComponent, setOpenSelectComponent] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>(func.function_name);
  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);

  const currentBlock = blocks.find((block) => block.block_id === blockId);

  const newBlockButton = (
    <LqdButton
      buttonsize="small"
      onClick={(event) => {
        const target = event.target as HTMLElement;
        setBlockName("");
        setOpenModal({ functionId: func.index, functionKey: target.id, view: "newBlock" });
      }}
    >
      Novo Bloco
    </LqdButton>
  );

  const newVariableButton = (
    <LqdButton
      buttonsize="small"
      onClick={(event) => {
        const target = event.target as HTMLElement;
        setOpenSelectComponent(false);
        setOpenModal({ functionId: func.index, functionKey: target.id, view: "newVariable" });
        dispatch(simulatorBuilderVariableSelected({ functionKey: "", id: "" }));
      }}
    >
      Nova Variável
    </LqdButton>
  );

  const editVariableButton = (
    <LqdIconButton
      buttonsize="small"
      onClick={(event) => {
        const target = event.target as HTMLElement;
        setOpenSelectComponent(false);
        setOpenModal({
          functionId: func.index,
          functionKey: target.id,
          view: "editVariable",
        });
      }}
      round="true"
      type="ghostLink"
    >
      <LqdEditIcon color="rgba(127, 135, 152, 1)" />
    </LqdIconButton>
  );

  const mainFunctionOptions = functionTemplates.map((template) => ({
    id: template.function_name,
    label: capitalizeString(template.function_label),
    value: template.function_name,
  }));

  const variableOptions = simulator.variables.map((variable) => ({
    category: variable.category,
    id: variable.id!,
    label: variable.label,
    type: variable.type || ("" as SimulatorVariableTemplateType),
    value: variable.name,
    variableValue: variable.value,
  }));

  const blockOptions: Array<{ id: string; label: string; type: SimulatorVariableTemplateType; value: number }> = blocks
    .filter((block) => block.block_id !== blockId)
    .map((block) => ({
      id: block.block_id.toString(),
      isBlock: true,
      label: block.name,
      type: "integer",
      value: block.block_id,
    }));

  const comparatorOptions: Array<{ id: string; label: string; type: SimulatorVariableTypes; value: string }> =
    comparatorVariables.map((variable, index) => ({
      id: variable.id || `${index}`,
      label: variable.label,
      type: variable.type || "comparator",
      value: variable.value,
    }));

  const mathComparatorOptions: Array<{ id: string; label: string; type: SimulatorVariableTypes; value: string }> =
    mathComparatorVariables.map((variable, index) => ({
      id: variable.id || `${index}`,
      label: variable.label,
      type: variable.type || "comparator",
      value: variable.value,
    }));

  const typeOptions: Array<{ id: string; label: string; type: SimulatorVariableTypes; value: string }> =
    typeComparatorVariables.map((variable, index) => ({
      id: `${index}`,
      label: variable.label,
      type: variable.value || "type",
      value: variable.value,
    }));

  const renderOptions: ObjectOf<
    Array<{
      id: string;
      label: string;
      type: SimulatorVariableTemplateType | SimulatorVariableTypes;
      value: number | string;
    }>
  > = {
    blocks: blockOptions,
    comparators: comparatorOptions,
    math_comparators: mathComparatorOptions,
    types: typeOptions,
    variables: variableOptions,
  };

  const variableInputs =
    functionTemplates.find((template) => template.function_name === selectedTemplate)?.variables || {};
  const resultInputs = functionTemplates.find((template) => template.function_name === selectedTemplate)?.result || {};
  const inputsLayout =
    functionTemplates.find((template) => template.function_name === selectedTemplate)?.layout || "default";

  useEffect(() => {
    let updatedFunction: SimulatorFunction;

    const initialStateFunction = simulatorInitialState.blocks
      .find((block) => block.block_id === blockId)
      ?.functions.find((funct) => funct.index === func.index);

    const emptyFunctionSchemaType = functionTemplates.find((template) => template.function_name === selectedTemplate);

    if (initialStateFunction && initialStateFunction.function_name === selectedTemplate) {
      updatedFunction = initialStateFunction;
    } else {
      updatedFunction = {
        ...func,
        function_name: emptyFunctionSchemaType?.function_name || "",
        param_type: emptyFunctionSchemaType?.param_type || "",
        result: {},
        type: emptyFunctionSchemaType?.type || "",
        variables: {},
      };
    }

    const updatedFunctions = currentBlock!.functions.map((funct) =>
      funct.index === func.index ? updatedFunction : funct
    );

    const updatedBlock = { ...currentBlock!, functions: updatedFunctions };

    const updatedBlocks = blocks.map((block) => (block.block_id === blockId ? updatedBlock : block));

    setBlocks(updatedBlocks);
  }, [selectedTemplate]);

  const onUpdateFunction = (
    key: string,
    value: string | number,
    remove: boolean = false,
    isResult: boolean = false
  ) => {
    const functionType = functionTemplates.find((template) => template.function_name === func.function_name)?.type;

    let updatedVariables = { ...func.variables };
    let updatedResult = { ...func.result };

    if (!isResult) {
      if (remove) delete updatedVariables[key];
      else updatedVariables = { ...func.variables, [key]: value };
    } else {
      updatedResult = { ...func.result, [key]: formatVariableName(value as string) };
    }

    const updatedFunc = {
      ...func,
      function_name: selectedTemplate,
      result: updatedResult,
      type: functionType || "",
      variables: updatedVariables,
    };

    setBlocks(
      blocks.map((block) =>
        block.block_id === blockId
          ? {
              ...block,
              functions: block.functions.map((f) => (f.index === func.index ? updatedFunc : f)),
            }
          : block
      )
    );
  };

  const handleFunctionListChange = (callback: () => void) => {
    callback();
    setShowMenu(null);
  };

  const mainFunctionOptionsToSort = [...mainFunctionOptions];
  const mainFunctionOptionsSorted = mainFunctionOptionsToSort.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Box
      sx={{
        alignItems: "flex-start",
        backgroundColor: "rgba(249, 249, 250, 1)",
        borderRadius: "20px",
        display: "flex",
        pb: 3,
        pt: 4,
        px: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(177, 227, 255, 1)",
          borderRadius: "50%",
          mr: 1.5,
          p: 1,
          svg: { display: "block" },
        }}
      >
        <LqdFunctionIcon />
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h4Headline">
            Função
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(156, 163, 175, 1)", fontSize: "14px", lineHeight: "16px", mt: 1 }}
            textstyle="c1Caption"
          >
            Crie funções em um bloco
          </LqdTypography>
          <Box sx={{ mt: 2.5, pt: 1.5 }}>
            <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
              Selecione a função desejada
            </LqdTypography>
            <LqdSelect
              onChange={setSelectedTemplate}
              options={mainFunctionOptionsSorted}
              placeholder="Selecione uma função"
              value={selectedTemplate}
            />
          </Box>
          <InputsRenderer
            currentBlock={currentBlock!}
            editVariableButton={editVariableButton}
            func={func}
            layout={inputsLayout}
            newBlockButton={newBlockButton}
            newVariableButton={newVariableButton}
            onBlurFunction={createResultVariables}
            onUpdateFunction={onUpdateFunction}
            openSelectComponent={openSelectComponent}
            renderOptions={renderOptions}
            resultInputs={resultInputs}
            variableInputs={variableInputs}
          />
        </Box>
        <LqdIconButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => setShowMenu(event.currentTarget)}
          round="true"
          type="filledSecundary"
        >
          <LqdEllipsisIcon />
        </LqdIconButton>
        <LqdMenu anchorEl={showMenu} onClose={() => setShowMenu(null)} open={Boolean(showMenu)}>
          <LqdMenuItem onClick={() => handleFunctionListChange(() => functionMethods.duplicate(func.index))}>
            <LqdDuplicateIcon />
            <Box sx={{ ml: 1 }}>Duplicar</Box>
          </LqdMenuItem>
          <LqdMenuItem
            disabled={currentBlock?.functions.length === 1}
            onClick={() => handleFunctionListChange(() => functionMethods.delete(func.index))}
          >
            <LqdTrashIcon />
            <Box sx={{ ml: 1 }}>Excluir</Box>
          </LqdMenuItem>
        </LqdMenu>
      </Stack>
    </Box>
  );
}
