import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import analysisSlice from "./features/analysisDetails/analysisDetailsSlice";
import automationsSlice from "./features/automations/automationsSlice";
import boardSlice from "./features/board/boardSlice";
import commonSlice from "./features/common/commonSlice";
import groupsSlice from "./features/groups/groupsSlice";
import certificateSlice from "./features/incomeCertificate/incomeCertificateSlice.ts";
import playSlice from "./features/play/playSlice";
import productBuilderSlice from "./features/productBuilder/productBuilderSlice";
import productsSlice from "./features/products/productsSlice";
import signatureSilce from "./features/signature/signatureSlice";
import simulatorBuilderSlice from "./features/simulatorBuilder/simulatorBuilderSlice";
import adminSlice from "./features/superadmin/adminSlice";
import uploadDocumentsSlice from "./features/uploadDocument/uploadDocumentsSlice.ts";
import usersSlice from "./features/users/usersSlice";
import registrySlice from "./features/registry/registrySlice.ts";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore actions or state paths that contain non-serializable values
        ignoredActions: ["signature/onSettingDocumentFile"],
        ignoredPaths: ["signature.documentFile"],
      },
    }),
  reducer: {
    admin: adminSlice,
    analysisDetails: analysisSlice,
    automations: automationsSlice,
    board: boardSlice,
    certificate: certificateSlice,
    common: commonSlice,
    groups: groupsSlice,
    play: playSlice,
    productBuilder: productBuilderSlice,
    products: productsSlice,
    registrySlice: registrySlice,
    signature: signatureSilce,
    simulatorBuilder: simulatorBuilderSlice,
    uploadDocuments: uploadDocumentsSlice,
    users: usersSlice,
  },
});

type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// These two are will be used inside the actual components:
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
