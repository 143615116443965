import { LqdEditIcon, LqdIconButton, LqdTable, LqdToggle } from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import SettingsRouteHeader from "@common/components/SettingsRouteHeader";
import { ToastNotification } from "@common/types/ToastNotification";
import { dateFormatter } from "@common/utils/dataFormatter";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Stack } from "@mui/material";
import { onUpdateSimulator } from "@simulatorBuilder/thunkFunctions";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFormView } from "@simulatorBuilder/types/SimulatorFormView";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  duplicateSimulator,
  onChangingCurrentView,
  onChangingIsEditSimulatorTenant,
  simulatorBuilderModalOpened,
} from "../simulatorBuilderSlice";
import SimulatorForm from "./SimulatorForm";

/** Página de gerenciamento de simuladores, do painel de superadmin. */
export default function SimulatorBuilderPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();

  const { simulatorData, simulators } = useAppSelector((state) => state.simulatorBuilder);

  const handleToggleActivateSimulator = async (simulator: Simulator) => {
    const simulatorState = simulator.is_active;
    const simulatorInfo = { ...simulator, is_active: !simulatorState };

    try {
      const requestData = {
        blocks: simulator.blocks,
        openModal: false,
        simulator: simulatorInfo,
        simulatorId: simulatorInfo.simulator_id,
      };
      await dispatch(onUpdateSimulator(requestData)).unwrap();
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    }
  };

  const onDuplicateClick = (simulator: Simulator) => {
    navigate(`/config/admin/${simulator.simulator_id}`);
    dispatch(duplicateSimulator(true));
    dispatch(simulatorBuilderModalOpened(simulator.simulator_id));
  };

  const onEditSimulatorClick = (simulator: Simulator) => {
    navigate(`/config/admin/${simulator.simulator_id}`);
    dispatch(onChangingCurrentView(SimulatorFormView.NAME));
    dispatch(simulatorBuilderModalOpened(simulator.simulator_id));
  };

  const onEditSimulatorStepResumeClick = (simulator: Simulator) => {
    navigate(`/config/admin/${simulator.simulator_id}`);
    dispatch(onChangingCurrentView(SimulatorFormView.RESUME));
    dispatch(simulatorBuilderModalOpened(simulator.simulator_id));
  };

  const onEditTenantClick = (simulator: Simulator) => {
    navigate(`/config/admin/${simulator.simulator_id}`);
    dispatch(onChangingCurrentView(SimulatorFormView.TENANT));
    dispatch(onChangingIsEditSimulatorTenant(true));
    dispatch(simulatorBuilderModalOpened(simulator.simulator_id));
  };

  const tableContent = Object.values(simulators).map((simulator) => {
    return [
      {
        primaryValue: simulator.name,
        tertiaryValue: { method: () => onEditSimulatorClick(simulator), text: "Editar" },
      },
      {
        primaryValue: simulator.tenant_name,
        tertiaryValue: { method: () => onEditTenantClick(simulator), text: "Editar" },
      },
      {
        primaryValue: dateFormatter(simulator.created_at * 1000).fullDateText,
        tertiaryValue: dateFormatter(simulator.created_at * 1000).fullTime,
      },
      {
        primaryValue: (
          <LqdToggle
            active={simulator.is_active}
            onChange={() => handleToggleActivateSimulator(simulator)}
            renderText
          />
        ),
      },
      {
        primaryValue: "",
        tertiaryValue: { method: () => onDuplicateClick(simulator), text: "Duplicar" },
      },
      {
        primaryValue: (
          <LqdIconButton onClick={() => onEditSimulatorStepResumeClick(simulator)} type="ghostIcon">
            <LqdEditIcon />
          </LqdIconButton>
        ),
      },
    ];
  });

  return (
    <>
      <SettingsRouteHeader
        buttonMethod={() => dispatch(simulatorBuilderModalOpened(""))}
        buttonTitle="Novo Simulador"
        sx={{ mt: 5 }}
        title="Simuladores"
        tooltipBody="Crie variáveis de entrada, funções e um dashboard personalizado."
        tooltipHeader="Configurar novo simulador"
      />
      <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
        <Stack sx={{ width: "87.5%" }}>
          <LqdTable<Simulator>
            height="450px"
            tableContent={tableContent}
            tableHeader={["Simulador", "Empresa", "Criação", "Status", "", ""]}
          />
        </Stack>
      </Stack>
      {simulatorData ? <SimulatorForm /> : null}
    </>
  );
}
