import { GetRegistryAnalysisParams } from "../api/GetRegistryAnalysis";
import { BoardFilter } from "../registrySlice";
import { RegistryAnalysisColumn } from "../utils/types";

/** Quantidade de conexões que são carregadas em cada paginação */
export const ANALYSIS_LOAD_AMOUNT = 10;

/**
 * Retorna um conjunto de query parameters para fazer as requests de GetAnalysis e GetTotalAnalysis.
 * @param column Coluna buscada
 * @param filter Filtro da board
 * @param searchQuery Texto de search da board
 * @param currentAnalysisCount Quantidade de conexões dentro da coluna selecionada
 * @param userProfile Perfil do usuário fazendo a busca
 * @returns
 */
export const buildGetRegistryAnalysisParams = (
  column: RegistryAnalysisColumn,
  filter: BoardFilter,
  searchQuery: string,
  currentAnalysisCount: number,
  userProfile: string
): GetRegistryAnalysisParams => {
  const hideArchivedAnalysis = userProfile === "viewer" || userProfile === "creator";
  const renderArchived = filter.archived && !hideArchivedAnalysis;
  const searchQueryAttrs: GetRegistryAnalysisParams = {
    analysis_id: searchQuery.trim() || undefined,
    analyst_name: searchQuery.trim() || undefined,
    archived: undefined,
    document: searchQuery.trim() || undefined,
    limit: ANALYSIS_LOAD_AMOUNT,
    offset: Math.floor(currentAnalysisCount / ANALYSIS_LOAD_AMOUNT) + 1,
    pass_id: searchQuery.trim() || undefined,
    peer_name: searchQuery.trim() || undefined,
    product_name: searchQuery.trim() || undefined,
  };
  const endDate =
    new Date(
      [filter.endDate.split("/")[1], filter.endDate.split("/")[0], filter.endDate.split("/")[2]].join("/") + " 23:59:59"
    ).getTime() / 1000;
  const startDate =
    new Date(
      [filter.startDate.split("/")[1], filter.startDate.split("/")[0], filter.startDate.split("/")[2]].join("/") +
        " 23:59:59"
    ).getTime() / 1000;
  if (!filter.humanDecision) searchQueryAttrs.human_interaction = false; // Bloquear as conexões com Human Decision

  let params: GetRegistryAnalysisParams = {};
  if (column === RegistryAnalysisColumn.COMPLETED) {
    let statusQuery: Array<string> = [];

    switch (renderArchived) {
      case true:
        statusQuery = ["finished"];
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    const parsedStatusQuery = statusQuery.join(",");

    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  if (column === RegistryAnalysisColumn.IN_PROGRESS) {
    let statusQuery: Array<string> = [];
    switch (renderArchived) {
      case true:
        statusQuery = ["in_progress", "unlabelled"];
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    // Coluna de Moderação
    if (filter.humanDecision && filter.unlabelled) {
      statusQuery = ["in_progress", "unlabelled"];
    }
    if (!filter.humanDecision && !filter.unlabelled) {
      statusQuery = [];
    }
    if (filter.unlabelled && !filter.humanDecision) {
      statusQuery = ["unlabelled"];
    }
    if (filter.humanDecision && !filter.unlabelled) {
      statusQuery = ["in_progress"];
    }

    const parsedStatusQuery = statusQuery.join(",");

    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  if (column === RegistryAnalysisColumn.PREPARATION) {
    switch (renderArchived) {
      case true:
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    const statusQuery: Array<string> = filter.unlabelled ? ["preparation", "unlabelled"] : [];

    const parsedStatusQuery = statusQuery.join(",");
    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  return params;
};
