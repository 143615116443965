export const getFormulaType = (getFormulaValue: string, index: number) => {
  if (getFormulaValue.includes("PMT")) {
    if (index === 0) return "(taxa)";
    if (index === 1) return "(periodo)";
    if (index === 2) return "(valor)";

    return "";
  }

  return "";
};
