import { get } from "../../common/utils/apiUtils";
import { AnalysisRaw } from "../../../features/board/types/AnalysisRaw";
// packages/analysis-v3/src/features/board/types/AnalysisRaw.tsx
export type GetRegistryAnalysisParams = {
  analysis_id?: string;
  analyst_name?: string;
  archived?: boolean;
  document?: string;
  finished?: boolean;
  from?: number;
  has_pendency?: boolean;
  human_interaction?: boolean;
  limit?: number;
  offset?: number;
  pass_id?: string;
  peer_name?: string;
  product_id?: string;
  product_name?: string;
  status?: string;
  to?: number;
};

export const getRegistryAnalysis = (params?: GetRegistryAnalysisParams) => {
  return get<{
    page: number;
    results: Array<AnalysisRaw>;
    total: number;
    total_pages: number;
  }>("/registry", { params });
};
