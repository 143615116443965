import { LqdButton, LqdCircularLoader } from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Stack } from "@mui/material";
import { updateSimulatorTesterResult } from "@simulatorBuilder/api/UpdateSimulatorTesterResult";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useErrorHandler } from "../../../../common/utils/useErrorHandler";
import { onUpdateSimulator } from "../../../thunkFunctions";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";

type SimulatorFormBlocksPositionSaveButtonProps = {
  blocks: Array<SimulatorFunctionBlock>;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export default function SimulatorFormBlocksPositionSaveButton(props: SimulatorFormBlocksPositionSaveButtonProps) {
  const { blocks, isEditing, setIsEditing } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { simulatorData, testerResult } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [loading, setLoading] = useState<boolean>(false);

  const handleSaveBlocksPositions = async () => {
    setLoading(true);

    try {
      const updatedSimulator = { ...simulator, blocks };
      const requestData = { blocks, simulator: updatedSimulator, simulatorId: simulator.simulator_id! };

      if (testerResult) {
        await updateSimulatorTesterResult({
          inputs: testerResult.inputs,
          is_results_active: false,
          result: null,
          simulator_id: simulator.simulator_id,
          simulator_name: simulator.name,
        });
      }

      await dispatch(onUpdateSimulator(requestData)).unwrap();
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" sx={{ width: "804px" }}>
      <LqdButton disabled={loading || !isEditing} onClick={handleSaveBlocksPositions}>
        {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Salvar"}
      </LqdButton>
    </Stack>
  );
}
