import {
  LqdButton,
  LqdDownIcon,
  LqdDuplicateIcon,
  LqdEllipsisIcon,
  LqdIconButton,
  LqdTrashIcon,
  LqdTypography,
  LqdUpIcon,
} from "@/liquid-components/src";
import { dialogCalled, dialogLoaded, toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { onUpdateSimulator } from "@simulatorBuilder/thunkFunctions";
import { SimulatorFormStepResumeView } from "@simulatorBuilder/types/SimulatorFormStepResumeView";
import affectedBlocksOnDelete from "@simulatorBuilder/utils/affectedBlocksOnDelete";
import { t } from "i18next";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { SimulatorFunctionBlock } from "../../types/SimulatorFunctionBlock";
import SimulatorBuilderValidator from "../SimulatorBuilderValidator";

type ResumeComponentState = "active_empty" | "active_filled" | "inactive";

interface SimulatorFormStepResumeCardProps {
  block?: SimulatorFunctionBlock;
  blocks?: Array<SimulatorFunctionBlock>;
  buttonAction: () => void;
  buttonTitle: string;
  changeBlocksPosition?: (index: number, type: "down" | "up") => void;
  componentIcon: ReactNode;
  componentState: ResumeComponentState;
  componentSubTitle: ReactNode | null;
  componentTitle: string;
  index?: number;
  setBlockId: Dispatch<SetStateAction<number | null>>;
  setBlockName: Dispatch<SetStateAction<string>>;
  setDisableTooltipOnHover?: (value: boolean) => void;
  setIsDuplicating: Dispatch<SetStateAction<boolean>>;
  setModalView: Dispatch<SetStateAction<SimulatorFormStepResumeView | null>>;
}

export default function SimulatorFormStepResumeCard(props: SimulatorFormStepResumeCardProps) {
  const {
    block,
    blocks,
    buttonAction,
    buttonTitle,
    changeBlocksPosition,
    componentIcon,
    componentState,
    componentSubTitle,
    componentTitle,
    index,
    setBlockId,
    setBlockName,
    setDisableTooltipOnHover,
    setIsDuplicating,
    setModalView,
  } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { simulator, simulatorVariablesWithoutDefaultAndInputs } = useSimulatorBuilder();

  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);

  const handleDeleteBlock = async () => {
    if (!blocks || !block) return;

    try {
      dispatch(dialogLoaded(true));
      const updatedBlocks: Array<SimulatorFunctionBlock> = blocks
        .filter((b) => b.block_id !== block.block_id)
        .map((filteredBlock) => ({
          ...filteredBlock,
          functions: filteredBlock.functions.map((func) => ({
            ...func,
            result: { ...func.result },
            variables: { ...func.variables },
          })),
        }));

      const variablesToBeRemoved = Object.entries(
        block!.functions
          .map((func) => {
            return func.variables;
          })
          .flat()
          .reduce((acc, vars) => ({ ...acc, ...vars }), {})
      ) // Combina todas as variáveis em um único objeto
        .filter(([, value]) => simulatorVariablesWithoutDefaultAndInputs.some((simVar) => simVar.name === value))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const resultVariableToBeRemoved = block!.functions
        .map((func) => func.result)
        .filter((result) => result)
        .flat()
        .filter((variable) =>
          Object.values(variable).some((vari) =>
            simulatorVariablesWithoutDefaultAndInputs.find((simVar) => simVar.name === vari)
          )
        );

      const updatedVariables = simulator.variables.filter((variable) => {
        const shouldRemoveFromVariables = Object.values(variablesToBeRemoved).some((removal) => {
          return removal === variable.name && variable.category !== "constant";
        });

        const shouldRemoveFromResults = resultVariableToBeRemoved.some((resultRemoval) => {
          return Object.values(resultRemoval).includes(variable.name);
        });

        return !shouldRemoveFromVariables && !shouldRemoveFromResults;
      });

      const requestData = {
        blocks: updatedBlocks,
        simulator: { ...simulator, variables: updatedVariables },
        simulatorId: simulator.simulator_id!,
      };
      await dispatch(onUpdateSimulator(requestData)).unwrap();

      const notification: ToastNotification = {
        message: "O bloco foi excluído com sucesso.",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      dispatch(dialogCalled(null));
      setShowMenu(null);
    }
  };

  const openDeleteModal = () => {
    const affectedBlocks = affectedBlocksOnDelete({ block, blocks });

    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => handleDeleteBlock(), title: "Confirmar Exclusão" }],
        body: affectedBlocks?.length
          ? "Excluir o bloco poderá impedir o funcionamento das funções dos blocos abaixo:"
          : "Excluir o bloco poderá impedir o funcionamento do simulador. Deseja continuar?",
        bodySecundary: affectedBlocks?.length ? "Deseja continuar?" : "",
        itemList: affectedBlocks,
        title: "Exclusão de Bloco",
        type: affectedBlocks?.length ? "list" : "default",
      })
    );
  };

  const handleDuplicateBlock = () => {
    setBlockName(`Cópia de ${block!.name}`);
    setBlockId(block!.block_id);
    setIsDuplicating(true);
    setShowMenu(null);
    setModalView(SimulatorFormStepResumeView.FUNCTIONS);
  };

  const componentLookupTable = {
    active_empty: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      border: "1px dashed rgba(212, 215, 220, 1)",
      iconBackgroundColor: "rgba(127, 135, 152, 1)",
      iconColor: "rgba(255, 255, 255, 1)",
    },
    active_filled: {
      backgroundColor: "rgba(249, 249, 250, 1)",
      border: "none",
      iconBackgroundColor: "rgba(177, 227, 255, 1)",
      iconColor: "rgba(33, 36, 42, 1)",
    },
    default: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      border: "1px dashed rgba(212, 215, 220, 1)",
      iconBackgroundColor: "rgba(127, 135, 152, 0.4)",
      iconColor: "rgba(255, 255, 255, 1)",
    },
    inactive: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      border: "1px dashed rgba(212, 215, 220, 1)",
      iconBackgroundColor: "rgba(127, 135, 152, 0.4)",
      iconColor: "rgba(255, 255, 255, 1)",
    },
  };

  const componentDisabled = componentState === "inactive";

  return (
    <Stack
      alignItems="center"
      direction="column"
      gap={1.5}
      onMouseEnter={setDisableTooltipOnHover ? () => setDisableTooltipOnHover(false) : () => null}
      sx={{
        backgroundColor: componentLookupTable[componentState].backgroundColor,
        border: componentLookupTable[componentState].border,
        borderRadius: "20px",
        maxWidth: "804px",
        mb: 3,
        p: 3.5,
        width: { md: "804px", sm: "575px", xs: "300px" },
      }}
    >
      {blocks && changeBlocksPosition && index !== undefined ? (
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <Stack direction="row" gap={1.5}>
            <Stack
              onMouseEnter={setDisableTooltipOnHover ? () => setDisableTooltipOnHover(true) : () => null}
              onMouseLeave={setDisableTooltipOnHover ? () => setDisableTooltipOnHover(false) : () => null}
            >
              <LqdIconButton
                disabled={index === 0}
                onClick={() => changeBlocksPosition(index, "up")}
                round="true"
                type="filledSecundary"
              >
                <LqdUpIcon color={index === 0 ? "rgba(127, 135, 152, 0.32)" : "rgba(127, 135, 152, 1)"} size={38} />
              </LqdIconButton>
            </Stack>
            <Stack
              onMouseEnter={setDisableTooltipOnHover ? () => setDisableTooltipOnHover(true) : () => null}
              onMouseLeave={setDisableTooltipOnHover ? () => setDisableTooltipOnHover(false) : () => null}
            >
              <LqdIconButton
                disabled={index === blocks.length - 1}
                onClick={() => changeBlocksPosition(index, "down")}
                round="true"
                type="filledSecundary"
              >
                <LqdDownIcon
                  color={index === blocks.length - 1 ? "rgba(127, 135, 152, 0.32)" : "rgba(127, 135, 152, 1)"}
                  size={38}
                />
              </LqdIconButton>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: componentLookupTable[componentState].iconBackgroundColor,
              borderRadius: "50%",
              color: componentLookupTable[componentState].iconColor,
              height: "34px",
              mr: 7,
              width: "34px",
            }}
          >
            {componentIcon}
          </Stack>
          <LqdIconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setShowMenu(event.currentTarget)}
            round="true"
            type="filledSecundary"
          >
            <LqdEllipsisIcon color="rgba(127, 135, 152, 1)" size={38} />
          </LqdIconButton>
          <Menu
            anchorEl={showMenu}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            elevation={1}
            id="basic-menu"
            MenuListProps={{ sx: { borderRadius: "12px", py: 0 } }}
            onClose={() => setShowMenu(null)}
            open={Boolean(showMenu)}
            slotProps={{
              paper: { sx: { borderRadius: "12px", boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.20)", mt: 1.5 } },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem onClick={handleDuplicateBlock} sx={{ height: "45px", minWidth: "157px", px: 2 }}>
              <LqdDuplicateIcon color="rgba(127, 135, 152, 1)" />
              <Box sx={{ ml: 1 }}>{t("Duplicar")}</Box>
            </MenuItem>
            <MenuItem onClick={openDeleteModal} sx={{ height: "45px", minWidth: "157px", px: 2 }}>
              <LqdTrashIcon color="rgba(127, 135, 152, 1)" />
              <Box sx={{ ml: 1 }}>{t("Excluir")}</Box>
            </MenuItem>
          </Menu>
        </Stack>
      ) : null}
      {!block ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: componentLookupTable[componentState].iconBackgroundColor,
            borderRadius: "50%",
            color: componentLookupTable[componentState].iconColor,
            height: "34px",
            width: "34px",
          }}
        >
          {componentIcon}
        </Stack>
      ) : null}
      <LqdTypography color={componentDisabled ? "rgba(33, 36, 42, 0.4)" : "rgba(33, 36, 42, 1)"} textstyle="h5Headline">
        {componentTitle}
      </LqdTypography>
      {componentSubTitle}
      {block && blocks && changeBlocksPosition && index !== undefined ? (
        <SimulatorBuilderValidator blockId={block.block_id} blocks={blocks} />
      ) : null}
      <LqdButton disabled={componentDisabled} onClick={buttonAction}>
        {buttonTitle}
      </LqdButton>
    </Stack>
  );
}
